import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Box, Slider } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";


export default function ImageCropper(props: any) {
    const cropperRef = useRef();
    const [scaleValue, setScaleValue] = useState(0.1)
    const onCrop = () => {
        //@ts-ignore
        const cropper = cropperRef.current?.cropper;
        fetch(cropper.getCroppedCanvas().toDataURL())
            .then(res =>
                res.blob())
            .then(blob => {
                props.getFile(new File([blob], 'cropImg.png', { type: blob.type }))
            })
    };

    const handleScalChange = (event: any, newValue: any) => {
        setScaleValue(newValue)
        if (cropperRef.current) {
            //@ts-ignore
            const cropper = cropperRef.current.cropper;
            if (cropper) {
                cropper.zoomTo(newValue || 1);
            }
        }
    };

    return (
        <>
            <Box className="profile-image-viewer">
                <Cropper
                    className="p-image-view"
                    src={props.imageLink}
                    style={{ height: 400, width: "100%" }}
                    // Cropper.js options
                    initialAspectRatio={1}
                    guides={false}
                    crop={onCrop}
                    //@ts-ignore
                    ref={cropperRef}
                    zoomOnWheel={false}
                    cropBoxResizable={false}
                    //@ts-ignore
                    dragMode={false}
                />
            </Box>
            {
                props.errorMessage ?
                    <Alert severity="error" icon={false}>
                        Looks like we don’t support the format, you’ve uploaded please try again with a different image
                    </Alert>
                    :
                    null
            }
            <Box>
                <Slider
                    min={0}
                    step={0.1}
                    max={10}
                    value={scaleValue}
                    onChange={handleScalChange}
                    aria-labelledby="input-slider"
                    className="user-profile-slider"
                />
            </Box>
        </>
    );
};