import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getErrorResponse, getMemberErrorMessages, getToken, removeAllToken } from "../../../components/src/commonUsage";
import { NavigationStackProp } from "react-navigation-stack";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavigationStackProp;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabValue: any;
  sortMenu: any;
  searchQuery: any;
  sortValue: any;
  selectedRows: any;
  listingData: any;
  selectAll: any;
  currentPage: any;
  perPage: any;
  isLoading: any;
  errorMessage: any;
  editView: any;
  contactEdit: any;
  companyEdit: any;
  companyArray: any;
  singleContactData: any;
  singleCompanyData: any;
  industryArray: any;
  csvData: any;
  countryData: any;
  companySize: any;
  companyNameFocus: boolean;
  countryFocus: boolean;
  industryFocus: boolean;
  employeesFocus: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Companycontactpage2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCompaniesApiId: string = "";
  getAllContactsApiId: string = "";
  getSelectCompaniesApiId: string = "";
  updateContactApiCallId: string = "";
  downloadCSVID: string = "";
  getAllIndustryApiId: string = "";
  updateCompanyApiCallId: string = "";
  countryApiCallId: string = "";
  getCompanySize: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabValue: 0,
      sortMenu: null,
      searchQuery: "",
      sortValue: "Latest",
      selectedRows: [],
      listingData: {
        "meta": {
          "total_pages": 0,
          "total_count": 0,
          "current_page": 0,
          "next_page": 0,
          "previous_page": null,
          "partner_id": null
        }
      },
      selectAll: false,
      currentPage: 1,
      perPage: 10,
      isLoading: false,
      errorMessage: null,
      editView: false,
      contactEdit: false,
      companyEdit: false,
      companyArray: [],
      singleContactData:
      {
        "first_name": "",
        "last_name": "",
        "customer_email": "",
        "company_lead": {
          "company_name": "",
        }
      },
      singleCompanyData: {
        company_name: "",
        company_website: "",
        country: "",
        id: "",
        industry: "",
      },
      industryArray: {},
      csvData:
      {
        "headers": [],
        "data": []
      },
      countryData: [],
      companySize: [],
      companyNameFocus: false,
      countryFocus: false,
      industryFocus: false,
      employeesFocus: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (getMemberErrorMessages(responseJson)) {
        removeAllToken()
        this.props.navigation.navigate('EmailAccountLoginBlock')
      } else {
        switch (apiRequestCallId) {
          case this.getAllCompaniesApiId:
            this.handleResponse(responseJson, false, '', 'listingData')
            break;
          case this.getAllContactsApiId:
            this.handleResponse(responseJson, false, '', 'listingData')
            break;
          case this.getSelectCompaniesApiId:
            this.handleResponse(responseJson, false, '', 'companyArray')
            break;
          case this.getAllIndustryApiId:
            this.handleResponse(responseJson.form_data, false, '', 'industryArray')
            break;
          case this.updateContactApiCallId:
            this.closeEditModal()
            this.getListingData('contact', 'normal')
            break;
          case this.updateCompanyApiCallId:
            this.closeEditModal()
            this.getListingData('company', 'normal')
            break;
          case this.downloadCSVID:
            this.handleResponse(responseJson, false, '', 'csvData')
            break;
          case this.countryApiCallId:
            this.setState({
              countryData: responseJson.countries.map((country: any) => ({
                label: country.name,
                value: country.name,
                ...country
              })),
            })
            break;
          case this.getCompanySize:
            this.setState({ companySize: responseJson })
            break;
          default:
            // Handle the default case here
            break;
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleResponse(responseJson: any, navigaion: any, path: any, statedata: any) {
    if (!responseJson.errors) {
      if (navigaion) {
        this.props.navigation.navigate(path);
      } else {
        const updatedState = { ...this.state, [statedata]: responseJson };
        this.setState(updatedState);
      }
    } else {
      // Check Error Response
      this.setState({
        errorMessage: getErrorResponse(responseJson)
      });
    }
  }
  handleTabChange(newValue: any) {
    this.setState({ tabValue: newValue })
  }
  handleSortClick = (event: any) => {
    event.persist();
    this.setState({ sortMenu: event.currentTarget })
  };

  handleSortClose = (text: any, type: any) => {
    this.setState({ sortValue: text, sortMenu: null, currentPage: 1 }, () => {
      this.getListingData(type, 'filter')
    })
  };
  handleMenuClose = () => {
    this.setState({ sortMenu: null });
  }
  handleSearchValue(text: any, type: any) {
    this.setState({
      searchQuery: text, currentPage: 1
    }, () => {
      this.getListingData(type, 'filter');

    })
  }

  getPageCount(type: any) {
    if (type === 'contact') {
      return this.state.listingData?.contact?.data?.length
    } else {
      return this.state.listingData?.data?.length
    }
  }
  getIndeterminate() {
    return this.state.selectedRows.length > 0 &&
      this.state.selectedRows.length < this.state.listingData.length
  }
  toggleSelectAllContact = (e: any) => {
    const { listingData } = this.state;
    if (e.target.checked) {
      const companyIds = listingData.contact.data.map((row: any) => row);
      this.setState({ selectedRows: companyIds, selectAll: true });
    } else {
      this.setState({ selectedRows: [], selectAll: false });
    }
  };
  toggleSelectAllCompany = (e: any) => {
    const { listingData } = this.state;
    if (e.target.checked) {
      const companyIds = listingData.data.map((row: any) => row);
      this.setState({ selectedRows: companyIds, selectAll: true });
    } else {
      this.setState({ selectedRows: [], selectAll: false });
    }
  };

  toggleSelectRow = (row: any) => {
    const { selectedRows } = this.state;
    const rowId = row;

    if (selectedRows.includes(rowId)) {
      this.setState({
        selectedRows: selectedRows.filter((id: any) => id !== rowId),
        selectAll: false,
      });
    } else {
      this.setState({
        selectedRows: [...selectedRows, rowId],
      });
    }

  };
  handlePageChange(value: any, type: any) {
    this.setState({ currentPage: parseInt(value) }, () => {
      this.getListingData(type, 'normal')
    });
  }
  getListingData = async (type: any, filter: any) => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (filter === 'normal') {
      if (type === "company") {
        this.getAllCompaniesApiId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.companyListingEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchQuery}&download_csv=false`
        );
        this.downloadCSV(`${configJSON.companyListingEndPoint}?sort=${this.state.sortValue}&query=${this.state.searchQuery}`)
      }
      if (type === "contact") {
        this.getAllContactsApiId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.contactListingEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchQuery}&download_csv=false`
        );
        this.downloadCSV(`${configJSON.contactListingEndPoint}?sort=${this.state.sortValue}&query=${this.state.searchQuery}`
        )

      }
    } else {
      if (type === "company") {
        this.getAllCompaniesApiId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.companyListingEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchQuery}&download_csv=false`
        );
        this.downloadCSV(
          `${configJSON.companyListingEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchQuery}`
        )
      }
      if (type === "contact") {
        this.getAllContactsApiId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.contactListingEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchQuery}&download_csv=false`
        );
        this.downloadCSV(
          `${configJSON.contactListingEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchQuery}`
        )
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  setEditView() {
    this.setState({ editView: true })
  }
  closeEditView() {
    this.setState({ editView: false })
  }
  openEditModal(type: string) {
    const updatedState = { ...this.state, [type]: true };
    this.setState(updatedState);
  }
  closeEditModal() {
    this.setState({
      companyEdit: false,
      contactEdit: false
    })
  }

  getFormikError(error: any, erros: any) {
    return error && Boolean(erros)
  }
  getFormikHelperText(error: any, erros: any) {
    return error && erros
  }
  getSelectCompanies = async () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSelectCompaniesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companySelectListingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  setSingleContactData(data: any) {
    this.setState({ singleContactData: data }, () => {
      this.openEditModal('contactEdit')
    });
  }
  setSingleCompanyData(data: any) {
    this.setState({ singleCompanyData: data }, () => {
      this.openEditModal('companyEdit')
    });
  }
  updateContact = (values: any) => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateContactEndPoint
    );


    const httpBody =
    {
      "data":
      {
        "id": values.id,
        "first_name": values.first_name,
        "last_name": values.last_name,
        "customer_email": values.customer_email,
      },
      "company_params": {
        "company_id": values.company_name.value
      }

    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  updateCompany = (values: any) => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCompanyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCompanyEndPoint
    );


    const httpBody =
    {
      "data":
      {
        "id": values.id,
        "company_name": values.company_name.label,
        "company_website": values.company_website,
        "country": values.country.label,
        "industry": values.industry.value,
        "company_size": values.employees.value
      }

    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  downloadCSV(url: any) {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downloadCSVID = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}&download_csv=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getIndustry = async () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllIndustryApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.industryListingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  navigateToLeads(data: any) {
    localStorage.setItem('company_id', data.id)
    localStorage.setItem('company_name', data.company_name)
    this.props.navigation.navigate('LeadDashboard')
  }
  navigateToNewDeal() {
    this.props.navigation.navigate('RegisterReferral')
  }
  getCountries = async () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryListingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fetchEmployeeSize = () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompanySize = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companySizeEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleFocus = (type: any) => {
    switch (type) {
      case 'company_name':
        this.setState({
          companyNameFocus: true
        });
        break;
      case 'country':
        this.setState({
          countryFocus: true
        });
        break;
      case 'industry':
        this.setState({
          industryFocus: true
        });
        break;
      case 'employees':
        this.setState({
          employeesFocus: true
        });
        break;
      default:
    }

  }
  handleBlur = (type: any) => {
    switch (type) {
      case 'company_name':
        this.setState({
          companyNameFocus: false
        });
        break;
      case 'country':
        this.setState({
          countryFocus: false
        });
        break;
      case 'industry':
        this.setState({
          industryFocus: false
        });
        break;
      case 'employees':
        this.setState({
          employeesFocus: false
        });
        break;
      default:
    }
  }
  // Customizable Area End
}
