// Customizable Area Start

import { Box } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import PageHeadingSkeleton from "../../../components/src/PageHeadingSkeleton";
import BoxSkeleton from "../../../components/src/BoxSkeleton";

interface MyProps {
  hasRightBlock ? : boolean;
  hasProfileIcon ? :boolean;
}



export default function DashboardSkeleton(props: MyProps) {  
  return (
    <Box className="skeleton-wrapper">
    <PageHeadingSkeleton hasProfileIcon={true} hasRightBlock={true} />
    <Box className="sort-deals-container">
      <Skeleton variant="text" width={170} className="label-skeleton" />
      <Skeleton className="dropdown-skeleton" width={100} height={35} />
    </Box>
    <BoxSkeleton hasViewMore={true} />
    <Box className="chart-row-skeleton">
      <Box className="chart-col-skeleton">
        <Box className="col-inner-wrap">
          <Box className="chart-content-wrap">
          <Skeleton className="chart-title" height={19} width={150} style={{marginBottom:'20px'}} />
          <Skeleton variant="text" className="" height={14} width={70} style={{marginBottom:'10px'}} />
          <Skeleton variant="text" height={24} width={30} />
          <Skeleton variant="circle" className="chart-skeleton" height={180} width={180} style={{backgroundColor:'transparent', border:'28px solid rgba(0, 0, 0, 0.11)', margin:'-25px auto 0'}} />                          
          <Box className="chart-legend-skeleton">
            <ul>
              <li><Skeleton height={12} width={100} /></li>
              <li><Skeleton height={12} width={100} /></li>
              <li><Skeleton height={12} width={100} /></li>
              <li><Skeleton height={12} width={100} /></li>
              <li><Skeleton height={12} width={100} /></li>
            </ul>
          </Box>                          
          </Box>
          <Box className="view-more-skeleton" style={{backgroundColor:'#fff'}}>
            <Skeleton variant="text" width={155} height={12} />
          </Box>
        </Box>
        
      </Box>
      <Box className="chart-col-skeleton">
        <Box className="col-inner-wrap">
          <Box className="chart-content-wrap">
            <Skeleton className="chart-title" height={26} width={150} />
            <Skeleton height={330} className="linechart-skeleton"></Skeleton>
          </Box>
          <Box className="view-more-skeleton" style={{backgroundColor:'#fff'}}>
          <Skeleton variant="text" width={155} height={12} />
        </Box>
        </Box>
      </Box>
    </Box>
    <Box className="report-box-skeleton">
      <Skeleton variant="text" width={211} height={19} className="title-skeleton" style={{marginBottom:'13px'}} />
      <Box className="report-row">
        <Box className="report-col">
          <Box className="report-inner-row">
            <Box className="report-inner-col">
              <Skeleton variant="text" width={100} height={11} style={{marginBottom:'10'}} />
              <Skeleton variant="text" width={80} height={14} />
            </Box>
            <Box className="report-inner-col">
              <Skeleton variant="text" width={100} height={11} style={{marginBottom:'10'}} />
              <Skeleton variant="text" width={80} height={14} />
            </Box>
            <Box className="report-inner-col">
              <Skeleton variant="text" width={100} height={11} style={{marginBottom:'10'}} />
              <Skeleton variant="text" width={80} height={14} />
            </Box>
          </Box>
        </Box>
        <Box className="report-col" style={{backgroundColor:'#FBF8FF', borderRadius:'8px' }}>
          <Box className="report-inner-row">
            <Box className="report-inner-col large-col">
              <Box style={{display:'flex', alignItems:'center'}}>
                <Box className="left-block">
                  <Skeleton height={40} width={40}></Skeleton>
                </Box>
                <Box className="right-block" style={{paddingLeft:'16px'}}>
                  <Skeleton variant="text" width={100} height={11} style={{marginBottom:'10'}} />
                  <Skeleton variant="text" width={80} height={14} />
                </Box>
              </Box>
            </Box>
            <Box className="report-inner-col">
              <Skeleton variant="text" width={100} height={11} style={{marginBottom:'10'}} />
              <Skeleton variant="text" width={80} height={14} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
  )
}

// Customizable Area End