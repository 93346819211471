import React from 'react';
import { Line } from "react-chartjs-2";
import { fillMissingDatesWithZeroCount, lineData } from './commonUsage';
import moment from 'moment';

export default function LineGraph(props: any) {

    const startDate = moment(props.dateRange[0]).startOf('day');
    const endDate = moment(props.dateRange[1]).startOf('day');
    const allDatesBetween: any[] = [];
    
    for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
        allDatesBetween.push({ date: date.format('YYYY-MM-DD'), count: 0 });
    }
    
    // Update counts from the data array
    allDatesBetween.forEach((dateObj) => {
        const matchingData = props.data.find((item: any) => item.date === dateObj.date);
        if (matchingData) {
            dateObj.count = matchingData.count;
        }
    });

    
    let commonData = fillMissingDatesWithZeroCount(props.data)
    
    let dateArray = props.calanderValue === "All Time"  ? commonData : allDatesBetween

    if (dateArray.length === 1) {
        const value = dateArray[0]
        dateArray[0] = { date: moment(value.date).subtract(2, 'day').format('YYYY-MM-DD'), count: 0 }
        dateArray[1] = { date: moment(value.date).subtract(1, 'day').format('YYYY-MM-DD'), count: 0 }
        dateArray[2] = value
        dateArray[3] = { date: moment(value.date).add(1, 'day').format('YYYY-MM-DD'), count: 0 }
        dateArray[4] = { date: moment(value.date).add(2, 'day').format('YYYY-MM-DD'), count: 0 }
    }

    return (
        <Line
            data={lineData(dateArray, 'normal')}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        enabled: true,
                        displayColors: false,
                        backgroundColor: "#3C3E49",
                        titleColor: "#fff",
                        bodyColor: "#fff",
                        padding: 16,
                        titleFont: {
                            family: 'Rubik',
                            weight: 'normal',
                            size: 10,
                        },
                        bodyFont: {
                            family: 'Rubik',
                            size: 14
                        },
                        callbacks: {
                            //@ts-ignore
                            title: (data) => {
                                return moment(data[0].label).format('ddd DD MMMM');
                            },
                            label: (data) => {
                                return `Deals Won ${data.formattedValue}`;
                            },
                        },
                    },
                    legend: { display: false },
                },
                elements: {
                    point: {
                        radius: 0, // Set point radius to 0
                        hitRadius: 10
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        drawBorder: false,
                        title: {
                            display: true,
                            text: 'Deals won',
                            font: {
                                size: 14,
                                family: 'Rubik',
                                weight: '300',
                                //@ts-ignore
                                color: '#83889E',
                            },
                        },
                        ticks: {
                            //@ts-ignore
                            minTicksLimit: 4,
                            maxTicksLimit: 5,
                        },
                        grid: {
                            color: '#ECEDF0',
                        },
                    },
                    x: {
                        beginAtZero: true,
                        ticks: {
                            maxTicksLimit: 5,
                            maxRotation: 0,
                            minRotation: 0,
                            align: 'center',
                            //@ts-ignore
                            callback: function (val: any, index: any) {
                                return [moment(this.getLabelForValue(val)).format('DD'), moment(this.getLabelForValue(val)).format('MMM')]
                            },
                        },
                    },
                },
                aspectRatio: 2,
            }}
        />
    )
}
