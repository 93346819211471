// Customizable Area Start

import { Box } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface myProps {
  hasRightBlock ? : boolean;
  hasProfileIcon ? :boolean;
}



export default function PageHeadingSkeleton(props: myProps) {  
  return (
    <Box className="page-heading-skeleton">
      <Box className="left-block">
        <Box style={{display:'flex', alignItems:'center'}}>
        {props.hasProfileIcon && (
          <Skeleton height={40} width={40} style={{marginRight:'16px'}} />
        )}
          <Box>
            <Skeleton height={16} width={105} style={{marginBottom:'8px'}}></Skeleton>
            <Skeleton height={16} width={340} ></Skeleton>            
          </Box>
        </Box>
      </Box>
      {props.hasRightBlock && (
      <Box className="right-block">                        
        <Skeleton width={150} height={48} />
      </Box>
      )}
    </Box>
  )
}

// Customizable Area End