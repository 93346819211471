import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { Ticket } from "./types";
import { handleResponseMessage } from "./helpers/handle-request-message";
import createRequestMessage from "./helpers/create-request-message";
import React, { RefObject } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  ticket: Ticket;
  isLoading: boolean;
  token: string;
  createLoading: boolean;
  selectMenu: any;
  addSupportModal: boolean;
  viewImageModal:boolean,
  textareaRef: RefObject<HTMLTextAreaElement>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TicketSystemController extends BlockComponent<
    Props,
    S,
    SS
> {
  // Customizable Area Start
  ticketCreateSchema: object = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string()
        .email()
        .required("Required"),
    subject: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });
  ticketSubmitApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleSubmitTicket = this.handleSubmitTicket.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      ticket: {
        name: "",
        email: "",
        subject: "",
        description: "",
      },
      isLoading: false,
      token: "",
      createLoading: false,
      selectMenu: null,
      addSupportModal:false,
      viewImageModal:false,
      textareaRef: React.createRef<HTMLTextAreaElement>()
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorJson = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.setState({
        isLoading: false,
      });

      switch (apiRequestCallId) {
        case this.ticketSubmitApi: {
          this.setState({
            createLoading: false,
          });
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.showAlert("", "Create Ticket Successfully");
            },
            onFail: () => {
              const { error } = responseJson;
              this.showAlert(
                  `Create Ticket Failed`,
                  `${error} \nPlease retry!`
              );
            },
          });
          break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCreateTicket(ticket: string) {
    this.setState({
      isLoading: true,
      createLoading: true,
    });

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ticketSubmitApi = requestMessage.messageId;

    createRequestMessage({
      requestMsg: requestMessage,
      endPoint: configJSON.createTicketEndPoint,
      method: configJSON.postApiMethodType,
      body: ticket,
    });
  }

  handleSubmitTicket(values: {
    name: string;
    email: string;
    subject: string;
    description: string;
  }) {
    const { name, email, subject, description } = values;

    const ticketData = JSON.stringify({
      ticket: {
        name: name,
        email: email,
        subject: subject,
        description: description,
      },
    });

    this.handleCreateTicket(ticketData);
  }
  handleSelectClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ selectMenu: event.currentTarget })
    }
  };
  handleSelectClose = (text: any) => {
    this.setState({ selectMenu: null}, () => {
      
    });
  };
  handleMenuClose = () => {
    this.setState({ selectMenu: null });
  }
  openSupportModal() {
    this.setState({
      addSupportModal: true,      
    })
  }
  openViewImageModal() {
    this.setState({
      viewImageModal: true,      
    })
  }
  closeModal() {
    this.setState({
      addSupportModal: false,
      viewImageModal:false,
    })
  }
  handleSendInputClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const exceptions = ["send-message-input", "close-btn"];
    const clickedElementClass = (event.target as HTMLElement).className;

    if (
      !exceptions.some((exception) => clickedElementClass.includes(exception)) &&
      !clickedElementClass.includes("share-doc-btn") &&
      !clickedElementClass.includes("purple-btn")
    ) {
      // Check if the clicked element is not one of the exceptions or the excluded classes
      if (this.state.textareaRef.current) {        
        this.state.textareaRef.current.focus();
      }
    }
  };
  // Customizable Area End
}
