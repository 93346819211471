// Customizable Area Start

import { Box } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface MyProps {
  hasRightBlock?: boolean;
  hasProfileIcon?: boolean;
}

export default function RegisterReferralSkeleton(props: MyProps) {
  return (
    <Box className="skeleton-wrapper register-deal-lead-skeleton">
      <Box className="form-title-block" style={{ marginBottom: '24px' }}>
        <Box className="left-block">
          <Skeleton height={24} width={140} />
          <Skeleton height={16} width={200} style={{ marginTop: '6px' }} />
        </Box>
      </Box>
      <Box className='form-outer-wrapper'>
        <Box className="left-block">
          <Box className="referral-form-wrapper referral-form-skeleton">
            <Box className="referral-heading-wrapper">
              <Box className="deal-heading-left">
                <Skeleton height={21} width="100%" />
              </Box>
              <Box className="deal-heading-right">
                <div className="view-draft-wrapper">
                  <Skeleton height={42} width={112} />
                </div>
                <Box className="btn-wrapper">
                  <Skeleton height={42} width={112} />
                  <Skeleton height={42} width={112} />
                </Box>
              </Box>
            </Box>
            <Box className="referral-form-content">
              <Box className="deal-form-container">
                <Box className="referral-title-skeleton">
                  <Skeleton height={21} width={400} />
                  <Skeleton height={36} width={36} />
                </Box>
                <Box className="form-element-list-skeleton">
                  <Box className="form-row-skeleton" style={{ margin: '0 0 16px' }}>
                    <Skeleton height={56}></Skeleton>
                    <Skeleton height={56}></Skeleton>
                  </Box>
                  <Box className="form-row-skeleton" style={{ margin: '0 0 16px' }}>
                    <Skeleton height={56}></Skeleton>
                    <Skeleton height={56}></Skeleton>
                  </Box>
                  <Box className="form-row-skeleton">
                    <Skeleton height={56}></Skeleton>
                  </Box>
                </Box>
              </Box>
              <Box className="deal-form-container">
                <Box className="referral-title-skeleton">
                  <Skeleton height={21} width={400} />
                  <Skeleton height={36} width={36} />
                </Box>
                <Box className="form-element-list-skeleton">
                  <Box className="form-row-skeleton">
                    <Skeleton height={56}></Skeleton>
                    <Skeleton height={56}></Skeleton>
                  </Box>
                </Box>
              </Box>
              <Box className="deal-form-container">
                <Box className="referral-title-skeleton">
                  <Skeleton height={21} width={400} />
                  <Skeleton height={36} width={36} />
                </Box>
                <Box className="form-element-list-skeleton">
                  <Box className="form-row-skeleton" style={{ margin: '0 0 16px' }}>
                    <Skeleton height={56}></Skeleton>
                    <Skeleton height={56}></Skeleton>
                  </Box>
                  <Box className="form-row-skeleton" style={{ margin: '0 0 16px' }}>
                    <Skeleton height={56}></Skeleton>
                    <Skeleton height={56}></Skeleton>
                  </Box>
                  <Box className="form-row-skeleton" style={{ margin: '0 0 16px' }}>
                    <Skeleton height={56}></Skeleton>
                    <Skeleton height={56}></Skeleton>
                  </Box>
                  <Box style={{ margin: '0 0 16px' }}>
                    <Skeleton height={120}></Skeleton>
                  </Box>
                  <Box className="form-row-skeleton">
                    <Skeleton height={56}></Skeleton>
                    <Skeleton height={56}></Skeleton>
                  </Box>
                </Box>
              </Box>
              <Box className="deal-form-container">
                <Box className="referral-title-skeleton">
                  <Skeleton height={21} width={400} />
                  <Skeleton height={36} width={36} />
                </Box>
                <Box className="form-element-list-skeleton">
                  <Box>
                    <Skeleton height={120}></Skeleton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

// Customizable Area End