import React from "react";

// Customizable Area Start
import {  
  Avatar,
  Box, Button, Grid, IconButton, Modal, TextField, Typography,
} from "@material-ui/core";
import TicketSystemController, {  
  Props,
} from "./TicketSystemController.web";
import './css/style.css';
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import { attachmentIconBlack, closeIcon, closePurpleIcon, headphoneIcon, sendIcon, tickGrayIcon, tickIcon } from "./assets";
//@ts-ignore
import profileImg from '../assets/defaultAsset.png'
// Customizable Area End

export default class SupportChat extends TicketSystemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start    
    return (
      <Box style={webStyle.container}>
      <Grid container>
        <Grid item xs={3} md={2} className="sidebar-wrapper">
          <SideNav {...this.props.navigation} />
        </Grid>
        <Grid item xs={9} md={10} className="main-content-wrapper" style={{paddingBottom:'0'}}>
          <Grid>
            <TopNavBar  currentPage={{ name: 'Support center', path: 'TicketSystem' }} subPage={{ name: 'Type in effect for Natasha messages' }} />
            <Box style={webStyle.mainContainer}>
              <Box className="custom-chat-outerwrap">
                <Box className="custom-chatbox">
                  <Box className="chatbox-inner-wrap">
                    <Box className="ticket-detail-description">
                      <Box  className="ticket-title-block">
                        <Box className="left-title-block">
                          <h3>Type in effect for Natasha messages</h3>
                          <Box className="ticket-status-block">
                            <Box className="status-badge resolved">Resolved</Box>
                            <Typography className="timeline-text">Added 24d ago</Typography>
                          </Box>
                        </Box>
                        <Box className="right-title-block">
                          <Button className="custom-btn light-btn btn-sm"><img src={tickIcon} alt="" />Mark resolved</Button>
                          <Button className="custom-btn border-btn btn-sm"><img src={closeIcon} alt="" style={{margin:'0'}} /></Button>
                        </Box>
                      </Box>
                      <Box className="ticket-description-block">
                        <Typography className="description-title">Description</Typography>
                        <Typography>
                          Once you submit, you will no longer be able to change your answers. Are you sure you want to submit the quiz? Are you sure you want to submit the quiz.  Are you sure you want to submit the quiz? Are you sure you want to submit the quiz.
                        </Typography>
                        <Box className="ticket-attachment-block">
                          <Typography className="attachment-title-block">2 Attachments</Typography>
                          <Box className="attached-items-wrap">
                            <Box className="attached-item">
                              <Box className="item-inner-wrap" onClick={() => {this.openViewImageModal()}}>
                              <img src={profileImg} alt="" />
                              </Box>
                            </Box>
                            <Box className="attached-item">
                              <Box className="item-inner-wrap">                          
                              <img src={profileImg} alt="" />
                              </Box>
                            </Box>
                            <Box className="attached-item">
                              <Box className="item-inner-wrap">                          
                              <img src={profileImg} alt="" />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="message-listing-block">
                      <Box className="message-block">
                        <Box className="message-inner-wrap">
                          <Box className="profile-picture-block">
                            <Avatar className="avtar-block purple-bg">H</Avatar>
                          </Box>
                          <Box className="message-content-block">
                            <Box className="message-detail">
                              <Typography className="name">Herold </Typography>
                              <Typography className="time">02:31 PM</Typography>
                            </Box>
                            <Box className="message-content">
                            <Typography>Once you submit, you will no longer be able to change your answers. Are you sure you want to submit the quiz? Are you sure you want to submit the quiz.</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="message-datetime-block">Yesterday</Box>

                      <Box className="message-block builder-message">
                        <Box className="message-inner-wrap">
                          <Box className="profile-picture-block">
                            <Avatar className="avtar-block purple-bg"><img src={headphoneIcon} alt="" /></Avatar>
                          </Box>
                          <Box className="message-content-block">
                            <Box className="message-detail">
                              <Typography className="name">Priya - Builder support</Typography>
                              <Typography className="time">02:31 PM</Typography>
                            </Box>
                            <Box className="message-content">
                            <Typography>Hello we are looking into your issue we need some more images to get more clarity on this can you share a few more here</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="message-block">
                        <Box className="message-inner-wrap">
                          <Box className="profile-picture-block">
                            <Avatar className="avtar-block purple-bg">H</Avatar>
                          </Box>
                          <Box className="message-content-block">
                            <Box className="message-detail">
                              <Typography className="name">Herold </Typography>
                              <Typography className="time">02:31 PM</Typography>
                            </Box>
                            <Box className="message-content">
                              <Typography>Let me share it with you</Typography>
                              <Box className="ticket-attachment-block">
                          <Typography className="attachment-title-block">4 files</Typography>
                          <Box className="attached-items-wrap">
                            <Box className="attached-item">
                              <Box className="item-inner-wrap">                          
                              <img src={profileImg} alt="" />
                              </Box>
                            </Box>
                            <Box className="attached-item">
                              <Box className="item-inner-wrap">                          
                              <img src={profileImg} alt="" />
                              </Box>
                            </Box>
                            <Box className="attached-item">
                              <Box className="item-inner-wrap">                          
                              <img src={profileImg} alt="" />
                              </Box>
                            </Box>
                            <Box className="attached-item">
                              <Box className="item-inner-wrap">                          
                              <img src={profileImg} alt="" />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="message-block builder-message">
                        <Box className="message-inner-wrap">
                          <Box className="profile-picture-block">
                            <Avatar className="avtar-block purple-bg"><img src={headphoneIcon} alt="" /></Avatar>
                          </Box>
                          <Box className="message-content-block">
                            <Box className="message-detail">
                              <Typography className="name">Priya - Builder support</Typography>
                              <Typography className="time">02:31 PM</Typography>
                            </Box>
                            <Box className="message-content">
                              <Typography>Let me share a few as well</Typography>
                              <Box className="ticket-attachment-block">
                                <Typography className="attachment-title-block">4 files</Typography>
                                <Box className="attached-items-wrap">
                                  <Box className="attached-item">
                                    <Box className="item-inner-wrap">                          
                                    <img src={profileImg} alt="" />
                                    </Box>
                                  </Box>
                                  <Box className="attached-item">
                                    <Box className="item-inner-wrap">                          
                                    <img src={profileImg} alt="" />
                                    </Box>
                                  </Box>
                                  <Box className="attached-item">
                                    <Box className="item-inner-wrap">                          
                                    <img src={profileImg} alt="" />
                                    </Box>
                                  </Box>
                                  <Box className="attached-item">
                                    <Box className="item-inner-wrap">                          
                                    <img src={profileImg} alt="" />
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="mark-as-resolve-block"><img src={tickGrayIcon} alt="" />marked as resolved</Box>
                    </Box>
                  </Box>                
                </Box>
                <Box className="chat-send-box">
                  <Box className="new-message-notification">
                    <Typography>
                      3 new messages
                    </Typography>
                  </Box>
                  <Box className="chat-sendbox-wrap">
                    <Box className="send-input-wrapper">
                      <Box className="sender-block">
                        <Avatar className="avtar-block purple-bg">H</Avatar>
                      </Box>
                      <Box className="send-input-block" onClick={this.handleSendInputClick}>
                        <Box className="left-block">
                          <TextField
                            name="type message"                                                    
                            data-test-id="txtInputCurrentPassword"                                                                                      
                            className="send-message-input"
                            multiline={true}
                            maxRows={4}
                            inputRef={this.state.textareaRef}
                            placeholder="Write a comment"
                          />
                          <Box className="image-list-wrap mt-1">                        
                            <Box className="attached-items-wrap">
                              <Box className="attached-item">
                                <Box className="item-inner-wrap">
                                <IconButton className="close-btn"><img src={closePurpleIcon} alt=""></img></IconButton>
                                <img src={profileImg} alt="" />
                                </Box>
                              </Box>
                              <Box className="attached-item">
                                <Box className="item-inner-wrap">
                                <IconButton className="close-btn"><img src={closePurpleIcon} alt=""></img></IconButton>
                                <img src={profileImg} alt="" />
                                </Box>
                              </Box>
                              <Box className="attached-item">
                                <Box className="item-inner-wrap">
                                <IconButton className="close-btn"><img src={closePurpleIcon} alt=""></img></IconButton>
                                <img src={profileImg} alt="" />
                                </Box>
                              </Box>
                              <Box className="attached-item">
                                <Box className="item-inner-wrap">
                                <IconButton className="close-btn"><img src={closePurpleIcon} alt=""></img></IconButton>
                                <img src={profileImg} alt="" />
                                </Box>
                              </Box>
                            </Box>                    
                          </Box>
                        </Box>
                        <Box className="send-btn-wrap">
                          <IconButton
                            aria-label="email"
                            edge="end"
                            className="share-doc-btn"
                            disableRipple
                          >
                            <img src={attachmentIconBlack} />
                          </IconButton>
                          <Button disabled className="custom-btn purple-btn send-btn">
                            <img src={sendIcon} style={{marginRight:'0'}} />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box >
          </Grid >
        </Grid >
      </Grid > 
      <Modal
          open={this.state.viewImageModal}
          onClose={this.closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-xlg view-image-modal"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <Box>
              <IconButton data-test-id="btn-close-invite-modal1" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
              <Box className="title-block">
                <Box className="image-title-block">
                  <Box className="profle-image-block">
                    <Avatar className="avtar-block purple-bg">H</Avatar>
                  </Box>
                  <Box className="profile-name-block">
                    <Box className="name-time-block">
                      <Typography className="name">Herold</Typography>
                      <Typography className="time">02:31 PM</Typography>
                    </Box>
                    <Typography className="image-name">Screenshot.jpg</Typography>
                  </Box> 
                </Box>
              </Box>
              <Box className="image-preview-block">
                <img src={profileImg} alt="" />
              </Box>
            </Box>
          
          </Box>
        </Modal>    
    </Box >
        // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0 0'
  },
};
// Customizable Area End
