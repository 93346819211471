Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "companycontactpage2";
exports.labelBodyText = "companycontactpage2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.companyListingEndPoint = "bx_block_companycontactpage2/companies"
exports.contactListingEndPoint = "bx_block_companycontactpage2/contacts"
exports.companySelectListingEndPoint = "bx_block_leadmanagement/leads/company_listing"
exports.updateContactEndPoint = "bx_block_companycontactpage2/contacts"
exports.putMethod = "PUT"
exports.industryListingEndPoint = "/bx_block_leadmanagement/leads/dropdown_values_for_lead_form"
exports.updateCompanyEndPoint = "bx_block_companycontactpage2/companies"
exports.countryListingEndPoint = "bx_block_leadmanagement/leads/country_listing"
exports.companySizeEndpoint="/bx_block_leadmanagement/leads/company_size_listing"
// Customizable Area End