import React, { useEffect, useState } from 'react';
import { Line } from "react-chartjs-2";
import { fillMissingDatesWithZeroCount, leadLineData, leadLineDataTwoSets } from './commonUsage';
import moment from 'moment';
import { ChartData, ScatterDataPoint } from 'chart.js';

export default function LeadsPageLineGraph(props: any) {
    const [graphData, setGraphData] = useState<any>({})

    useEffect(() => {
        const allDatesBetween1: any[] = [];
        const allDatesBetween2: any[] = [];

        if (props.label === 'Closed won/lost deals') {
            const startDate = moment(props.dateRange[0]).startOf('day');
            const endDate = moment(props.dateRange[1]).startOf('day');

            for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
                allDatesBetween1.push({ date: date.format('YYYY-MM-DD'), count: 0 });
                allDatesBetween2.push({ date: date.format('YYYY-MM-DD'), count: 0 });
            }

            // Update counts from the data1 array
            allDatesBetween1.forEach((dateObj) => {
                const matchingData = props.data1.find((item: any) => item.date === dateObj.date);
                if (matchingData) {
                    dateObj.count = matchingData.count;
                }
            });

            // Update counts from the data2 array
            allDatesBetween2.forEach((dateObj) => {
                const matchingData = props.data2.find((item: any) => item.date === dateObj.date);
                if (matchingData) {
                    dateObj.count = matchingData.count;
                }
            });
        } else {
            const startDate = moment(props.dateRange[0]).startOf('day');
            const endDate = moment(props.dateRange[1]).startOf('day');

            for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
                allDatesBetween1.push({ date: date.format('YYYY-MM-DD'), count: 0 });
            }

            // Update counts from the data array
            allDatesBetween1.forEach((dateObj) => {
                const matchingData = props.data.find((item: any) => item.date === dateObj.date);
                if (matchingData) {
                    dateObj.count = matchingData.count;
                }
            });
        }

        let commonData = fillMissingDatesWithZeroCount(props.data);
        let commonData1 = fillMissingDatesWithZeroCount(props.data1);
        let commonData2 = fillMissingDatesWithZeroCount(props.data2);

        let dateArray = props.calanderValue === "All Time" ? commonData : allDatesBetween1;
        let wonDataFilter = props.calanderValue === "All Time" ? commonData1 : allDatesBetween1;
        let lostDataFilter = props.calanderValue === "All Time" ? commonData2 : allDatesBetween2;

        if (dateArray.length === 1) {
            const value = dateArray[0]
            dateArray[0] = { date: moment(props.dateRange[0]).subtract(2, 'day').format('YYYY-MM-DD'), count: 0 }
            dateArray[1] = { date: moment(props.dateRange[0]).subtract(1, 'day').format('YYYY-MM-DD'), count: 0 }
            dateArray[2] = value
            dateArray[3] = { date: moment(props.dateRange[0]).add(1, 'day').format('YYYY-MM-DD'), count: 0 }
            dateArray[4] = { date: moment(props.dateRange[0]).add(2, 'day').format('YYYY-MM-DD'), count: 0 }
        }

        if (wonDataFilter.length === 1) {
            const value = wonDataFilter[0]
            wonDataFilter[0] = { date: moment(props.dateRange[0]).subtract(2, 'day').format('YYYY-MM-DD'), count: 0 }
            wonDataFilter[1] = { date: moment(props.dateRange[0]).subtract(1, 'day').format('YYYY-MM-DD'), count: 0 }
            wonDataFilter[2] = value
            wonDataFilter[3] = { date: moment(props.dateRange[0]).add(1, 'day').format('YYYY-MM-DD'), count: 0 }
            wonDataFilter[4] = { date: moment(props.dateRange[0]).add(2, 'day').format('YYYY-MM-DD'), count: 0 }
        }

        if (lostDataFilter.length === 1) {
            const value = lostDataFilter[0]
            lostDataFilter[0] = { date: moment(props.dateRange[0]).subtract(2, 'day').format('YYYY-MM-DD'), count: 0 }
            lostDataFilter[1] = { date: moment(props.dateRange[0]).subtract(1, 'day').format('YYYY-MM-DD'), count: 0 }
            lostDataFilter[2] = value
            lostDataFilter[3] = { date: moment(props.dateRange[0]).add(1, 'day').format('YYYY-MM-DD'), count: 0 }
            lostDataFilter[4] = { date: moment(props.dateRange[0]).add(2, 'day').format('YYYY-MM-DD'), count: 0 }
        }
        if (props.label === 'Closed won/lost deals') {
            setGraphData(leadLineDataTwoSets(wonDataFilter, lostDataFilter))
        } else {
            setGraphData(leadLineData(dateArray))
        }
    }, [props.label])
    if (Object.keys(graphData).length)
        return (
            <Line
                data={graphData}
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        tooltip: {
                            enabled: true,
                            displayColors: false,
                            backgroundColor: "#3C3E49",
                            titleColor: "#fff",
                            bodyColor: "#fff",
                            padding: 16,
                            titleFont: {
                                family: 'Rubik',
                                weight: 'normal',
                                size: 10,
                            },
                            bodyFont: {
                                family: 'Rubik',
                                size: 14
                            },
                            callbacks: {
                                //@ts-ignore
                                title: (data) => {
                                    return moment(data[0].label).format('ddd DD MMMM');
                                },
                                label: (data) => {
                                    return `Total ${data.formattedValue}`;
                                },
                            },
                        },
                        legend: { display: false },
                    },
                    elements: {
                        point: {
                            radius: 0, // Set point radius to 0
                            hitRadius: 10
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            drawBorder: false,
                            title: {
                                display: true,
                                text: props.label,
                                font: {
                                    size: 14,
                                    family: 'Rubik',
                                    weight: '300',
                                    //@ts-ignore
                                    color: '#83889E',
                                },
                            },
                            ticks: {
                                //@ts-ignore
                                minTicksLimit: 4,
                                maxTicksLimit: 5,
                            },
                            grid: {
                                color: '#ECEDF0',
                            },
                        },
                        x: {
                            beginAtZero: true,
                            ticks: {
                                maxTicksLimit: 5,
                                maxRotation: 0,
                                minRotation: 0,
                                align: 'center',
                                //@ts-ignore
                                callback: function (val: any, index: any) {
                                    return [moment(this.getLabelForValue(val)).format('DD'), moment(this.getLabelForValue(val)).format('MMM')]
                                },
                            },
                        },
                    },
                    aspectRatio: 2,
                }}
            />
        )
    return <></>
}
