// Customizable Area Start
import { Box } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface MyProps {
  
}

export default function ProfilePageSkeleton(props: MyProps) {    
  return (
  <Box className="profile-skeleton-wrapper" style={{padding:'22px 0'}}>
    <Box className="profile-outer-block">
    <Box className="profile-left-wrapper">
      <Box className="profile-image-wrap">
        <Skeleton height={142} width={142} variant="circle" />
        <Box className="upload-image-link">
          <Skeleton height={16} width={105} style={{margin:'0 auto'}} />
        </Box>
      </Box>
    </Box>
    <Box className="profile-right-wrapper">
      <Box className="right-inner-wrap">
        <Box className="profile-title-block">
          <Box className="profile-left-block">
            <Skeleton height={24} width={130} style={{marginBottom:'12px'}} />
            <Skeleton height={16} width={200} />
          </Box>
          <Box className="profile-right-block">
            <Skeleton height={12} width={60} />
          </Box>
        </Box>
        <Box className="profile-email-container">
          <Box className="info-row">
            <Box className="info-title">
              <Skeleton height={16} width={105} />
            </Box>
            <Box className="info-content">
              <Skeleton height={16} width={165} />
            </Box>
          </Box>
          <Box className="info-row">
            <Box className="info-title">
              <Skeleton height={16} width={105} />
            </Box>
            <Box className="info-content">
              <Skeleton height={16} width={135} />
            </Box>
          </Box>
          <Box className="info-row">
            <Box className="info-title">
              <Skeleton height={16} width={105} />
            </Box>
            <Box className="info-content">
              <Skeleton height={16} width={105} />
            </Box>
          </Box>
        </Box>

        <Box className="password-info-block">
          <Box>
            <Skeleton height={16} width={105} style={{marginBottom:'16px'}} />
            <Skeleton height={16} width={165} />
          </Box>
          <Box className="change-password-block">
            <Skeleton height={16} width={105} />
          </Box>
        </Box>
        <Box className="logout-block">
          <Skeleton width={120} height={48} />
        </Box>
        <Box className="get-in-touch-block">
          <Skeleton height={16} width={200} />
        </Box>
      </Box>
    </Box>
    </Box>
  </Box>
  )
}
// Customizable Area End