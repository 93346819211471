import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
  Avatar,  
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,  
  Tooltip,  
} from "@material-ui/core";
import TicketSystemController, {  
  Props,
} from "./TicketSystemController.web";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import { checkIcon, downArrow, searchIcon, unCheckIcon } from "../../assetsdashboard/src/assets";
import Pagination from "@material-ui/lab/Pagination";
import { emptySvg } from "../../leadmanagement/src/assets";
import { plusIcon, closeIcon, attachmentIcon, closeDarkIcon } from "./assets";
import './css/style.css';
//@ts-ignore
import profileImg from '../assets/defaultAsset.png'
import PageHeadingSkeleton from "../../../components/src/PageHeadingSkeleton";
import TableFilterSkeleton from "../../../components/src/TableFilterSkeleton";
import TableSkeleton from "../../../components/src/TableSkeleton";
// Customizable Area End

export default class TicketSystem extends TicketSystemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectMenu } = this.state;
    const selectDealOpen = Boolean(selectMenu);
    return (
      <Box style={webStyle.container}>
      <Grid container>
        <Grid item xs={3} md={2} className="sidebar-wrapper">
          <SideNav {...this.props.navigation} />
        </Grid>
        <Grid item xs={9} md={10} className="main-content-wrapper">
          <Grid>
            <TopNavBar currentPage={{ name: 'Support center', path: 'TicketSystem' }} />
            <Box style={webStyle.mainContainer}>
            <Box className="lead-dashboard-heading dashboard-nav-container">
              <Box className="dashboard-heading">
                <Box>
                  <Typography className="deal-heading-main">
                    Support center
                  </Typography>
                  <Typography className="deal-heading-sub">
                  Raise a support request to resolve any of your query.
                  </Typography>
                </Box>
              </Box>
              <Box style={webStyle.rightAlign}>
                <Button
                  data-test-id={"btnAddSupportRequest"}
                  variant="contained"
                  color="primary"
                  className="heading-button"
                  onClick={() => {this.openSupportModal()}}
                >
                  <Typography className="deal-lead-btn-text" style={{display:'flex', alignItems:'center' }}>
                    <img src={plusIcon} alt="plus" style={{marginRight:'8px'}} /> Add Support Request
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box className="filter-block-wrapper">
              <Box style={webStyle.centerAlign} className="filter-inner-wrap">
                <Box className="filter-left-block">                            
                  <div className="display-flex">
                    <label>
                      <Typography className="open-deals-sub-text">
                        120 Requests
                      </Typography>
                    </label>
                    <Button
                      data-test-id={"btnSelectStagesFiter"}
                      className="select-deal-button"
                      id="select-button"
                      aria-haspopup="true"
                      onClick={(event) => this.handleSelectClick(event)}
                      disableRipple                                  
                    >
                      <Typography className="select-stage-text">
                        Stages
                      </Typography>
                      <Typography className="asset-select-option-text stages-filter-text">
                        All<label className="dropdown-arrow"> <img src={downArrow} /></label>
                      </Typography>
                    </Button>
                    <Menu
                      style={{
                        top: '52px',
                        borderRadius: '8px',
                      }}
                      id="select-menu"
                      aria-labelledby="select-button"
                      anchorEl={selectMenu}
                      open={selectDealOpen}
                      onClose={this.handleMenuClose}
                    >
                      <MenuItem className="menu-item-text" onClick={() => this.handleSelectClose('All')}>All</MenuItem>
                      <MenuItem className="menu-item-text" onClick={() => this.handleSelectClose('In progress')}>In progress</MenuItem>
                      <MenuItem className="menu-item-text" onClick={() => this.handleSelectClose('Closed won')}>Closed won</MenuItem>
                      <MenuItem className="menu-item-text" onClick={() => this.handleSelectClose('Closed lost')}>Closed lost</MenuItem>
                    </Menu>
                  </div>
                </Box>
                <Box className="filter-right-block">
                  <Grid container style={webStyle.rightAlign}>                              
                    <Grid>
                      <div>
                        <TextField
                          data-test-id={"searchLeadText"}
                          className="search-input"
                          fullWidth
                          size="small"
                          placeholder="Search Requests"
                          variant="outlined"                                    
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={searchIcon} className="ml-10" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid >
                </Box >
              </Box >
            </Box>
            <Box>
              <TableContainer className="lead-table" component={Paper}>
                <Table aria-label="simple table">
                  <TableHead style={{ background: '#f8f9fa' }}>
                    <TableRow>
                      <TableCell style={{ width: '48px' }} className="lead-table-heading checkbox-cell">
                      <Checkbox
                          checked={false}
                          icon={<img src={unCheckIcon} alt="Unchecked Icon" />}
                          checkedIcon={<img src={checkIcon} alt="Checked Icon" />}
                        />
                      </TableCell>
                      <TableCell style={{ width: '400px' }} className="lead-table-heading">request title</TableCell>
                      <TableCell style={{ width: '250px' }} className="lead-table-heading">Requester</TableCell>
                      <TableCell style={{ width: '256px' }} className="lead-table-heading">STAGE</TableCell>
                      <TableCell style={{ width: '158px' }} className="lead-table-heading">added on</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>                    
                      <TableRow>
                        <TableCell className="lead-table-data-company checkbox-cell">
                        <Checkbox
                          checked={false}
                          icon={<img src={unCheckIcon} alt="Unchecked Icon" />}
                          checkedIcon={<img src={checkIcon} alt="Checked Icon" />}
                        />
                        </TableCell>                       
                        
                        <TableCell className="lead-table-data-company">
                          <Typography style={{cursor:'pointer', display:'inline-block'}}>Type in effect for Natasha messages</Typography><span className="count-badge">2</span></TableCell>
                        <TableCell className="lead-table-data-row flex-row"><Tooltip arrow  placement="top-start" title="hellen@goldfinch.com"><Avatar src={profileImg} className="avtar-block"></Avatar></Tooltip> Hellen Kristy</TableCell>
                        <TableCell className="lead-table-data-row"><span className="status-badge open">open</span></TableCell>
                        <TableCell className="lead-table-data-row">24d ago</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="lead-table-data-company checkbox-cell">
                        <Checkbox
                          checked={false}
                          icon={<img src={unCheckIcon} alt="Unchecked Icon" />}
                          checkedIcon={<img src={checkIcon} alt="Checked Icon" />}
                        />
                        </TableCell>                       
                        
                        <TableCell className="lead-table-data-company"><Typography style={{cursor:'pointer', display:'inline-block'}}>Type in effect for Natasha messages</Typography></TableCell>
                        <TableCell className="lead-table-data-row flex-row"><Tooltip arrow  placement="top-start" title="hellen@goldfinch.com"><Avatar className="avtar-block purple-bg">H</Avatar></Tooltip>Hellen Kristy</TableCell>
                        <TableCell className="lead-table-data-row"><span className="status-badge resolved">Resolved</span></TableCell>
                        {/* <TableCell className="lead-table-data-row"><span className="status-badge open">Open</span></TableCell> */}
                        <TableCell className="lead-table-data-row">24d ago</TableCell>
                      </TableRow>
                    
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className="table-page-info-block">
                <Box className="rows-info">
                <Typography>
                  Showing 10 results
                </Typography>
                </Box>
                <Box className="pagination-wrapper">
                <Pagination count={5}  page={1} shape="rounded" />
                </Box>
              </Box>
            </Box>
            <Box className="empty-table-wrapper">
              <Table>
                <TableHead style={{ background: '#f8f9fa' }}>
                  <TableRow>
                    <TableCell className="lead-table-heading" style={{ width: '48px' }}>
                    <Checkbox
                        checked={false}
                        icon={<img src={unCheckIcon} alt="Unchecked Icon" />}
                        checkedIcon={<img src={checkIcon} alt="Checked Icon" />}
                      />
                    </TableCell>
                    <TableCell style={{ width: '400px' }} className="lead-table-heading">request title</TableCell>
                    <TableCell style={{ width: '250px' }} className="lead-table-heading">Requester</TableCell>
                    <TableCell style={{ width: '256px' }} className="lead-table-heading">STAGE</TableCell>
                    <TableCell style={{ width: '158px' }} className="lead-table-heading">added on</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <Box className="empty-data-block">
                <Box className="empty-content">
                <Box className="empty-img">
                  <img src={emptySvg} />
                </Box>
                <Box className="content">
                  <Typography className="title-sm">You haven't added any support request.</Typography>                
                  <Box className="empty-btn">
                    <Button data-test-id={"btnEmptyNavigateDeal"} className="custom-btn light-btn btn-sm">
                      <Typography className="empty-button-text">
                        Add support request
                      </Typography>
                    </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* skeleton start  */}
            <Box className="skeleton-wrapper">
              <PageHeadingSkeleton hasRightBlock={true} />
              <TableFilterSkeleton />
              <TableSkeleton />
            </Box>
            {/* skeleton end  */}
            </Box >
          </Grid >
        </Grid >
      </Grid >
      <Modal
          open={this.state.addSupportModal}
          onClose={this.closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-sm add-member-modal"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <Box>
              <IconButton data-test-id="btn-close-invite-modal1" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
              <Box className="title-block">
                <Typography variant="h4">
                  4 Submit support request
                </Typography>
                <Typography>Describe your feedback, We will reach back to you soon</Typography>
              </Box>
            </Box>
            <Box>
              <form>
                <Box className="form-inner-wrap">
                  <Box className="form-block-wrap">
                    <Box>
                      <TextField
                        data-test-id={"searchLeadText"}
                        className="secondary-form-control"
                        fullWidth
                        size="small"
                        placeholder="What is issue/query your have regarding you account?"
                        variant="filled"
                      />
                    </Box>
                    <Box className="mt-1">
                    <TextField
                      className="secondary-form-control"
                      inputProps={{
                        maxLength: 100 // Use "maxLength" instead of "maxlength" for HTML attribute.
                      }}
                      name="Describe"
                      fullWidth
                      variant="filled"
                      placeholder="Describe your query /issue"
                      multiline
                    />
                    </Box>
                    <Box className="mt-1">
                      <Box className="attachment-block-wrap">
                        <Typography className="attachment-label">Attachments</Typography>
                        <Typography className="add-attachment-link"><img src={attachmentIcon} alt="" />Add Attachment</Typography>
                      </Box>
                    </Box>
                    <Box className="mt-1">
                      <Box className="attached-items-wrap">
                        <Box className="attached-item">
                          <Box className="item-inner-wrap">
                          <IconButton className="close-btn"><img src={closeDarkIcon} alt=""></img></IconButton>
                          <img src={profileImg} alt="" />
                          </Box>
                        </Box>
                        <Box className="attached-item">
                          <Box className="item-inner-wrap">
                          <IconButton className="close-btn"><img src={closeDarkIcon} alt=""></img></IconButton>
                          <img src={profileImg} alt="" />
                          </Box>
                        </Box>
                        <Box className="attached-item">
                          <Box className="item-inner-wrap">
                          <IconButton className="close-btn"><img src={closeDarkIcon} alt=""></img></IconButton>
                          <img src={profileImg} alt="" />
                          </Box>
                        </Box>
                        <Box className="attached-item">
                          <Box className="item-inner-wrap">
                          <IconButton className="close-btn"><img src={closeDarkIcon} alt=""></img></IconButton>
                          <img src={profileImg} alt="" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="btn-wrap">
                    <Button
                      data-test-id="btn-close-invite-modal2"
                      onClick={() => this.closeModal()}
                      fullWidth
                      size="small"
                      variant="contained"
                      type="button"
                      className="custom-btn">Cancel</Button>
                    <Button                      
                      data-test-id="btn-invite-member"                      
                      fullWidth
                      size="small"
                      variant="contained"
                      type="button"
                      className="custom-btn green-btn">
                      Submit
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Modal>
    </Box >
        // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex'
  },
  centerAlign: {
    alignItems: 'center'
  },
};
// Customizable Area End
