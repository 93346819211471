import React from "react";

import {
  Box,
  Button,
  Grid,
  Typography,
  // Customizable Area Start
  Tooltip as MuiTooltip,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import './css/styles.css';
import { capitalizeFirstLetter, downloadFile, getActualValue, getCommaValue, getContractInfo, getToken } from "../../../components/src/commonUsage";
import { emptyContract } from "./assets";
import Tooltip from "@material-ui/core/Tooltip";
import { infoIconLg, } from "./assets";
// Customizable Area End

import Contractmanagement2Controller, {
  Props
} from "./Contractmanagement2Controller";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import PageHeadingSkeleton from "../../../components/src/PageHeadingSkeleton";
import Skeleton from "@material-ui/lab/Skeleton";

export default class ContractDetail extends Contractmanagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (getToken('partner_role') == 'member') {
      this.props.navigation.navigate('DashboardWeb')
    }
    await this.getProfileInfo();
  }
  // Customizable Area End

  render() {
    const { name, partner_product_type, total_contract_value, mdf_value, discount_percent_given, spiff } = this.state.profileData.partner.data.attributes
    const { commission, commencement_date, partnership_type } = this.state.profileData.partner.data.attributes.partner_contract
    const url = this.state.profileData.partner.data.attributes?.sf_contract_link

    const PriceDiscount = () => {
      return (partnership_type !== 'Referral' ? <Box className="contract-col" >
        <Typography className="cotract-item-title">
          Pricing discount
        </Typography>
        <Typography className="cotract-item-content">
          {getActualValue(discount_percent_given, '%')}
        </Typography>
      </Box> : <></>
      )
    }
    return (
      // Customizable Area Start
      <Box style={webStyle.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Contract details', path: 'contract-details' }} />
              <Box style={webStyle.mainContainer}>
                {this.state.isLoading ?
                  < Box className="skeleton-wrapper">
                    <PageHeadingSkeleton hasRightBlock={true} />
                    <Box className="contract-detail-wrapper">
                      <Box className="contract-inner-wrap">
                        <Skeleton height={20} width={200} style={{ marginBottom: '45px' }} />
                        <Box className="contract-detail-list">
                          <Box className="contract-block">
                            <Box className="contract-row">
                              <Box className="contract-col" >
                                <Skeleton height={16} width={150} style={{ marginBottom: '8px' }} />
                                <Skeleton height={16} width={250} />
                              </Box>
                              <Box className="contract-col" >
                                <Skeleton height={16} width={150} style={{ marginBottom: '8px' }} />
                                <Skeleton height={16} width={250} />
                              </Box>
                              <Box className="contract-col" >
                                <Skeleton height={16} width={150} style={{ marginBottom: '8px' }} />
                                <Skeleton height={16} width={250} />
                              </Box>
                            </Box>
                          </Box>
                          <Box className="contract-block information-block">
                            <Box className="contract-row">
                              <Box className="contract-col" >
                                <Skeleton height={16} width={150} style={{ marginBottom: '8px' }} />
                                <Skeleton height={16} width={250} />
                              </Box>
                              <Box className="contract-col" >
                                <Skeleton height={16} width={150} style={{ marginBottom: '8px' }} />
                                <Skeleton height={16} width={250} />
                              </Box>
                            </Box>
                            <Box className="contract-row">
                              <Box className="contract-col" >
                                <Skeleton height={16} width={150} style={{ marginBottom: '8px' }} />
                                <Skeleton height={16} width={250} />
                              </Box>
                              <Box className="contract-col" >
                                <Skeleton height={16} width={150} style={{ marginBottom: '8px' }} />
                                <Skeleton height={16} width={250} />
                              </Box>
                            </Box>
                            <Box className="contract-row">
                              <Box className="contract-col" >
                                <Skeleton height={16} width={150} style={{ marginBottom: '8px' }} />
                                <Skeleton height={16} width={250} />
                              </Box>
                              <Box className="contract-col" >
                                <Skeleton height={16} width={150} style={{ marginBottom: '8px' }} />
                                <Skeleton height={16} width={250} />
                              </Box>
                            </Box>
                            <Box className="contract-row">
                              <Box className="contract-col" >
                                <Skeleton height={16} width={150} style={{ marginBottom: '8px' }} />
                                <Skeleton height={16} width={250} />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  :
                  <>
                    <Box className="lead-dashboard-heading dashboard-nav-container">
                      <Box className="dashboard-heading contract-page-heading">
                        <Box>
                          <Typography className="deal-heading-main has-badge">
                            Contract details
                            <Box className="tooltip-info-wrapper">
                              <MuiTooltip
                                classes={{ tooltip: 'custom-info-tooltip', }}
                                title="Access detailed information about your partnership contract with this feature. Review terms and conditions, contract summaries, and download copies for reference and verification." placement="bottom" arrow>
                                <img className="info-icon" src={infoIconLg} alt="infoIconLg"></img>
                              </MuiTooltip >
                            </Box>
                            {getContractInfo(partnership_type) && <span className="badge">Signed</span>}
                          </Typography>
                          <Typography className="deal-heading-sub">
                            View the contract and it's summary signed with Builder.ai
                          </Typography>
                        </Box>
                      </Box>
                      <Box style={webStyle.rightAlign}>
                        {
                          url ?
                            <a href={url} target="_blank" style={{ textDecoration: "none" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                className="heading-button"
                              >
                                <Typography className="deal-lead-btn-text">
                                  View contract
                                </Typography>
                              </Button>
                            </a> :
                            <Tooltip title={"Contract file not available, check again later."} placement="bottom" arrow >
                              <Button
                                data-test-id={"btnRegisteredDealLead"}
                                variant="contained"
                                color="primary"
                                className="heading-button"
                                disableRipple
                                style={{ opacity: "0.8" }}
                              >
                                View contract
                              </Button>
                            </Tooltip>
                        }
                      </Box>
                    </Box>
                    <Box className="contract-detail-wrapper">
                      <Box className="contract-inner-wrap">
                        <Box className="contract-title-block">
                          <Typography variant="h3">Contract summary</Typography>
                        </Box>
                        {
                          getContractInfo(partnership_type) ?
                            <Box className="contract-detail-list">
                              <Box className="contract-block">
                                <Box className="contract-row">
                                  <Box className="contract-col" >
                                    <Typography className="cotract-item-title">
                                      Name
                                    </Typography>
                                    <Typography className="cotract-item-content">
                                      {capitalizeFirstLetter(name)}
                                    </Typography>
                                  </Box>
                                  <Box className="contract-col" >
                                    <Typography className="cotract-item-title">
                                      Platform:
                                    </Typography>
                                    <Typography className="cotract-item-content">
                                      {getCommaValue(partner_product_type)}
                                    </Typography>
                                  </Box>
                                  <Box className="contract-col" >
                                    <Typography className="cotract-item-title">
                                      Partnership program
                                    </Typography>
                                    <Typography className="cotract-item-content">
                                      {getCommaValue(partnership_type)}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="contract-block information-block">
                                <Box className="contract-row">
                                  <Box className="contract-col" >
                                    <Typography className="cotract-item-title">
                                      Commit/ ACV quota
                                    </Typography>
                                    <Typography className="cotract-item-content">
                                      {total_contract_value !== null
                                        ?
                                        `${getActualValue(total_contract_value, '$')}`
                                        :
                                        ""
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className="contract-col" >
                                    <Typography className="cotract-item-title">
                                      MDF value
                                    </Typography>
                                    <Typography className="cotract-item-content">
                                      {getActualValue(mdf_value, '$')}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="contract-row">
                                  <Box className="contract-col" >
                                    <Typography className="cotract-item-title">
                                      SPIFF:
                                    </Typography>
                                    <Typography className="cotract-item-content">
                                      {
                                        spiff
                                      }
                                    </Typography>
                                  </Box>

                                  {this.state.profileData.partner.data.attributes?.referral_commission ?
                                    <Box className="contract-col" >
                                      <Typography className="cotract-item-title">
                                        Referral commission:
                                      </Typography>
                                      <Typography className="cotract-item-content">
                                        {`${this.state.profileData.partner.data.attributes?.referral_commission}%`}
                                      </Typography>
                                    </Box>
                                    : ""}

                                  {this.state.profileData.partner.data.attributes?.reseller_commission ?
                                    <Box className="contract-col" >
                                      <Typography className="cotract-item-title">
                                        Reseller commission:
                                      </Typography>
                                      <Typography className="cotract-item-content">
                                        {`${this.state.profileData.partner.data.attributes?.reseller_commission}%`}
                                      </Typography>
                                    </Box>
                                    : ""}
                                </Box>

                                <Box className="contract-row">

                                  <PriceDiscount />

                                  <Box className="contract-col" >
                                    <Typography className="cotract-item-title">
                                      Contract commencement date
                                    </Typography>
                                    <Typography className="cotract-item-content">
                                      {commencement_date}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box className="contract-row">
                                  <Box className="contract-col" >
                                    <Typography className="cotract-item-title">
                                      Auto-renewal term:
                                    </Typography>
                                    <Typography className="cotract-item-content">
                                      Yes, one (1) year successive term.
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            :
                            <Box className="contract-detail-list">
                              <Box className="empty-screen">
                                <img src={emptyContract} alt="" />
                              </Box>
                              <Box className="empty-screen">
                                <Box className="empty-text-container">
                                  <Typography className="empty-text-container-label">Your contract details is yet to be updated here, check back later.</Typography>
                                </Box>
                              </Box>
                            </Box>
                        }

                      </Box>
                    </Box>
                  </>}


              </Box >
            </Grid >
          </Grid >
        </Grid >
      </Box >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex'
  },
};
// Customizable Area End
