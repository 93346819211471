// Customizable Area Start

import { Box, Grid } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface MyProps {
  hasRightBlock?: boolean;
  hasProfileIcon?: boolean;
}



export default function UpdateLeadSkeleton(props: MyProps) {
  return (
    <Box className="skeleton-wrapper register-deal-lead-skeleton">
      <Box className="form-title-block" style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '760px' }}>
        <Box className="left-block" style={{ marginBottom: '24px' }}>
          <Skeleton height={24} width={140} />
        </Box>
      </Box>
      <Box className='form-outer-wrapper has-right-col'>
        <Box className="left-block">
          <Box className="referral-form-wrapper referral-form-skeleton">
            <Box className="referral-form-content">
              <Box className="referral-form-container">
                <Box className="referral-title-skeleton">
                  <Skeleton height={16} width={166} />
                </Box>
                <Box className="deal-form-internal-container">
                  <Box>
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "22px" }}>
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "22px" }}>
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>

              <Box className="referral-form-container">
                <Box className="referral-title-skeleton">
                  <Skeleton height={16} width={166} />
                </Box>
                <Box className="deal-form-internal-container">
                  <Box>
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "22px" }}>
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "22px" }}>
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>

              <Box className="referral-form-container">
                <Box className="referral-title-skeleton">
                  <Skeleton height={16} width={166} />
                </Box>
                <Box className="deal-form-internal-container">
                  <Box>
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "22px" }}>
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "22px" }}>
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "22px" }}>
                    <Grid item>
                      <Skeleton height={16} width={342} style={{ marginBottom: '5px' }} />
                      <Skeleton height={21} width="100%" style={{ marginBottom: '5px' }} />
                      <Skeleton height={21} width="100%" />
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "22px" }}>
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                        <Skeleton height={21} width={208} />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>

              <Box className="referral-form-container">
                <Box className="referral-title-skeleton">
                  <Skeleton height={16} width={166} />
                </Box>
                <Box className="deal-form-internal-container">
                  <Box style={{ marginTop: "22px" }}>
                    <Grid item>
                      <Skeleton height={16} width={166} style={{ marginBottom: '5px' }} />
                      <Skeleton height={21} width={208} />
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="right-block">
          <Box className="deal-info-wrap">
            <Box className="deal-update-save-form-1">
              <Box className="w-100">
                <Grid className="grid-button-space">
                  <Grid item xs={12} md={12}>
                    <Skeleton height={48} width={140} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Skeleton height={48} width={140} />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="deal-update-save-form-2">
              <Box className="deal-title-block">
                <Box>
                  <Skeleton height={20} width={150} style={{ marginBottom: '23px' }} />
                </Box>
                <Grid className="update-deal-value-container">
                  <Grid container className="deal-row">
                    <Grid item xs={5} md={5} className="left-col">
                      <Skeleton height={16} width={80} style={{ margin: '0 0 6px' }} />
                      <Skeleton height={16} width={105} />
                    </Grid>
                    <Grid item xs={7} md={7} className="right-col">
                      <Skeleton height={16} width={80} style={{ margin: '0 0 6px' }} />
                      <Skeleton height={16} width={105} />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className="info-content-row" style={{ borderTop: 'none', paddingTop: '0' }}>
                <Box className="update-deal-value-container">
                  <Box className="inner-content-row">
                    <Box className="title">
                      <Skeleton height={16} width={105} />
                    </Box>
                    <Box className="value">
                      <Skeleton height={16} width={185} />
                    </Box>
                  </Box>
                  <Box className="inner-content-row">
                    <Box className="title">
                      <Skeleton height={16} width={105} />
                    </Box>
                    <Box className="value">
                      <Skeleton height={16} width={185} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="info-content-row">
                <Box className="update-deal-value-container">
                  <Box className="inner-content-row">
                    <Box className="title">
                      <Skeleton height={16} width={105} />
                    </Box>
                    <Box className="value">
                      <Skeleton height={16} width={185} />
                    </Box>
                  </Box>
                  <Box className="inner-content-row">
                    <Box className="title">
                      <Skeleton height={16} width={105} />
                    </Box>
                    <Box className="value">
                      <Skeleton height={16} width={185} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="info-content-row">
                <Box className="update-deal-value-container">
                  <Box className="inner-content-row">
                    <Box className="title">
                      <Skeleton height={16} width={105} />
                    </Box>
                    <Box className="value">
                      <Skeleton height={16} width={185} />
                    </Box>
                  </Box>
                  <Box className="inner-content-row">
                    <Box className="title">
                      <Skeleton height={16} width={105} />
                    </Box>
                    <Box className="value">
                      <Skeleton height={16} width={185} />
                    </Box>
                  </Box>
                  <Box className="inner-content-row">
                    <Box className="title">
                      <Skeleton height={16} width={105} />
                    </Box>
                    <Box className="value">
                      <Skeleton height={16} width={185} />
                    </Box>
                  </Box>

                </Box>
              </Box>
              <Box className="info-content-row description-row">
                <Box className="inner-content-row">
                  <Box className="title">
                    <Skeleton height={16} width={105} />
                  </Box>
                  <Box className="value">
                    <Skeleton height={16} width={185} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

// Customizable Area End