Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "commissionreports2";
exports.labelBodyText = "commissionreports2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getComissionDataEndPoint = "bx_block_commissionreports2/commissions"
exports.getPayoutsEndPoint = "bx_block_commissionreports2/payouts"
exports.fileSizeError="Your file is too large to handle. Reduce the size of the file below [5MB] and try again."
exports.fileTypeError="Your file is in a wrong format. Convert the file to PDF and try again."
exports.putMethod = "PUT";
// Customizable Area End