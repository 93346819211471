import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./css/style.css";
import { builderPartner, openEye, visiblityOff } from "./assets";
import { Formik } from 'formik';
import { signInValidationSchema } from "../../../components/src/validations";
import Loader from "../../../components/src/Loader.web";
//@ts-ignore
import loginBg from '../assets/login_background.png';
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>

        <Box
        className="login-container-box"
        style={{
          backgroundImage: `url(${loginBg})`              
        }} >
          <Box className="login-container">
            {
              this.state.loader ?
                <Loader loading={this.state.loader} />
                :
                <Formik
                  enableReinitialize
                  initialValues={{ email: this.state.email, password: this.state.password }}
                  validationSchema={signInValidationSchema}
                  onSubmit={() => {
                    this.setState({
                      errorMessage: null,
                      loader: true
                    })
                    this.doEmailLogIn()
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <Box style={{ textAlign: 'center' }}>
                        <div className="logo-block">
                          <img src={builderPartner} className="login-partner-logo" />
                        </div>
                        <div className="title-block">
                          <Typography className="login-text">
                            {this.labelTitle}
                          </Typography>
                          {/* <Typography className="register-link">
                            <label className="login-sub-text">or </label><label className="login-sub-text login-sub-text-color">{this.state.labelHeader}</label> 
                          </Typography> */}
                        </div>
                        <Box className="login-input-container">
                          <Box>
                            <TextField
                              size="small"
                              name="email"
                              variant="filled"
                              data-test-id="txtInputEmail"
                              label={"Email"}
                              fullWidth={true}
                              value={formik.values.email}
                              onChange={(e) => {
                                this.setEmail(e.target.value)
                                formik.handleChange(e)
                              }}
                              error={this.getFormikError(formik.touched.email, formik.errors.email) || this.state.errorMessage}
                              helperText={this.getFormikHelperText(formik.touched.email, formik.errors.email)}
                            />
                          </Box>
                          <Box sx={{ marginTop: "17px" }}>
                            <TextField
                              name="password"
                              size="small"
                              variant="filled"
                              data-test-id="txtInputPassword"
                              type={this.state.enablePasswordField ? "password" : "text"}
                              label={"Password"}
                              fullWidth={true}
                              value={formik.values.password}
                              onChange={(e) => {
                                this.setPassword(e.target.value)
                                formik.handleChange(e)
                              }}
                              error={this.getFormikError(formik.touched.email, formik.errors.password) || this.state.errorMessage}
                              helperText={this.getFormikHelperText(formik.touched.email, formik.errors.password)}
                              InputProps={{
                                endAdornment:
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      edge="end"
                                    >
                                      {this.state.enablePasswordField ? (
                                        <img src={openEye} alt="" />
                                      ) : (
                                        <img src={visiblityOff} alt="" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                              }}
                            />
                          </Box>
                          {
                            this.state.errorMessage !== null &&
                            <Box>
                              <Typography className="error-text">
                                {this.state.errorMessage}
                              </Typography>
                            </Box>
                          }
                          <Box
                          >
                            <Typography
                              data-test-id={"btnForgotPassword"}
                              style={{
                                marginTop: "24px",
                                marginBottom: "24px",
                                cursor: 'pointer',
                                width: 'fit-content'
                              }}
                              onClick={() => this.goToForgotPasswordScreen()}
                              className="forgot-password-text">
                              Forgot Password?
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              data-test-id={"btnEmailLogIn"}
                              variant="contained"
                              color="primary"
                              className="custom-btn green-btn"
                              fullWidth
                              type="submit"
                              disabled={formik.isSubmitting}
                            >
                              <Typography className="btn-signin-text">
                                Sign in {/*UI Engine::From Sketch*/}
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  )}

                </Formik>
            }
          </Box>
        </Box>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}
