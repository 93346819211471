import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { dealSuccess, rightCheck } from "./assets";
// Customizable Area End
import LeadManagementController, {
  Props
} from "./LeadManagementController";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import './css/styles.css'

export default class LeadCreateSuccess extends LeadManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Deals and leads', path: 'leads-deals' }} subPage={{ name: 'Register new deal' }} />
              <Box style={webStyles.mainContainer}>
                <Grid>
                  <Typography className="deal-heading-main">
                    Register New Deal
                  </Typography>
                  <Typography className="deal-heading-sub">
                    Please Enter the information required inorder to register a new deal.
                  </Typography>
                </Grid>
                <Box className="deal-form">
                  <Box className="deal-form-container">
                    <div>
                      <img src={dealSuccess} />
                    </div>
                    <Typography className="deal-registered">Deal has been registered! <img className="ml-10" src={rightCheck} /></Typography>
                    <Typography className="deal-sub-registered">Details entered have been recorded, and added to the database</Typography>
                    <Box>
                      <Grid container>
                        <Grid className="mr-16">
                          <Button
                            data-test-id={"btnViewDeal"}
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="button"
                            onClick={() => this.navigateToViewDeal()}
                            className="view-deal-button">
                            <Typography className="view-btn-text">
                              View Deal
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid>
                          <Button
                            data-test-id={"btnNewDeal"}
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="button"
                            onClick={() => this.navigateToNewDeal()}
                            className="register-deal-button">
                            <Typography className="register-new-text">
                              Register New Deal
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '20px 0'
  },
};
// Customizable Area End
