Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "contractmanagement2";
exports.labelBodyText = "contractmanagement2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.inviteEmailEndPoint = "bx_block_partner/partners"
exports.getMembersApiEndPoint = 'bx_block_partner/partners/partner_member_listing'
exports.resendEmailApiEndPoint = 'bx_block_partner/partners/invite_email'
exports.changeRoleApiEndPoint = 'bx_block_partner/partners/partner_role'
exports.parnterShowEndPoint = 'bx_block_partner/partners/partner_show'
exports.countryListingEndPoint = "bx_block_leadmanagement/leads/country_listing"
exports.saveBankDetailsEndPoint = "bx_block_partner/partner_bank_details"
exports.getBankDetailsEndPoint = "bx_block_partner/partner_bank_details/partner_bank_listing"
exports.updateBankDeatailsEndPoint = "bx_block_partner/partner_bank_details"
exports.putMethod = "PUT"
exports.deleteMethod = "DELETE"
// Customizable Area End