// Customizable Area Start
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface myProps {
  loading?: boolean;
  contentLoader?: boolean;
}

interface Row {

}

export default function TableSkeleton(props: myProps) {
  const singleRow: Row = {};
  const rows: Row[] = Array.from({ length: 10 }, (_, index) => ({ ...singleRow, id: index + 1 }));
  return (
    <>
      <Box className="table-skeleton-wrapper">
        <TableContainer className="lead-table" component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ background: '#f8f9fa' }}>
              <TableRow style={{ height: '40px' }}>
                <TableCell className="lead-table-heading" ><Skeleton height={16} width={105} /></TableCell>
                <TableCell className="lead-table-heading"><Skeleton height={16} width={105} /></TableCell>
                <TableCell className="lead-table-heading"><Skeleton height={16} width={105} /></TableCell>
                <TableCell className="lead-table-heading"><Skeleton height={16} width={105} /></TableCell>
                <TableCell className="lead-table-heading"><Skeleton height={16} width={105} /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow className="row-container" >
                  <TableCell className="lead-table-data-row"><Skeleton height={20} width={176} /></TableCell>
                  <TableCell className="lead-table-data-row"><Skeleton height={20} width={140} /></TableCell>
                  <TableCell className="lead-table-data-row"><Skeleton height={20} width={80} /></TableCell>
                  <TableCell className="lead-table-data-row"><Skeleton height={20} width={140} /></TableCell>
                  <TableCell className="lead-table-data-row"><Skeleton height={20} width={96} /></TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <Box className="pagination-skeleton">
        <Skeleton height={16} width={105} />
        <Box className="page-list-skeleton" style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton height={30} width={30} style={{ marginRight: '5px' }} />
          <Skeleton height={30} width={30} style={{ marginRight: '5px' }} />
          <Skeleton height={30} width={30} style={{ marginRight: '5px' }} />
          <Skeleton height={30} width={30} style={{ marginRight: '5px' }} />
          <Skeleton height={30} width={30} style={{ marginRight: '5px' }} />
          <Skeleton height={30} width={30} />
        </Box>
      </Box> */}
    </>
  )
}
// Customizable Area End