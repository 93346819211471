import React from 'react';
import { Doughnut } from "react-chartjs-2";
import { donughtData } from './commonUsage';

export default function DoughnutGraph(props: any) {
    const noDataExist=props.data.every((item:{date:string,count:number})=>item.count===0)
    return (
        
            <Doughnut
                options={{
                    cutout: 47,
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        tooltip: {
                            "enabled":noDataExist?false: true,
                            position:"nearest",
                            displayColors: false,
                            backgroundColor: "#3C3E49",
                            titleColor: "#fff",
                            bodyColor: "#fff",
                            padding: 5,
                            bodyFont: {
                                family: 'Rubik',
                                size: 11
                            },
                            callbacks: {
                                label: (data) => {
                                    return `${data.label} ${data.formattedValue}`;
                                },
                            },
                        },
                        legend: { display: false },
                    },
                }}
                data={donughtData(props.data)}
            />
            
    )
}
