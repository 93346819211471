// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
require('dotenv').config()
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Companycontactpage2 from "../../blocks/companycontactpage2/src/Companycontactpage2";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Brandingcustomisation2 from "../../blocks/brandingcustomisation2/src/Brandingcustomisation2";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Contractmanagement2 from "../../blocks/contractmanagement2/src/Contractmanagement2.web";
import ContractDetail from "../../blocks/contractmanagement2/src/ContractDetail.web";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Settings2 from "../../blocks/settings2/src/Settings2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Commissionreports2 from "../../blocks/commissionreports2/src/Commissionreports2";
import Posintegration3 from "../../blocks/posintegration3/src/Posintegration3";
import Salesforceintegration2 from "../../blocks/salesforceintegration2/src/Salesforceintegration2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Singleleaddealpage3 from "../../blocks/singleleaddealpage3/src/Singleleaddealpage3";
import EmailAccountLoginBlockWeb from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordInstruction from "../../blocks/forgot-password/src/ForgotPasswordInstruction.web";
import NewPasswordWeb from "../../blocks/forgot-password/src/NewPassword.web";
import SuccessResetPassword from "../../blocks/forgot-password/src/SuccessResetPassword.web";
import EmailAccountRegistrationInvite from "../../blocks/email-account-registration/src/EmailAccountRegistrationInvite.web";
import DashboardWeb from "../../blocks/dashboard/src/Dashboard.web"
import LeadDashboard from "../../blocks/leadmanagement/src/LeadDashboard.web"
import LeadCreateSuccess from "../../blocks/leadmanagement/src/LeadCreateSuccess.web"
import ReferralsDetail from "../../blocks/leadmanagement/src/ReferralsDetail.web"
import AssetsDashboardWeb from "../../blocks/assetsdashboard/src/Assetsdashboard.web"
import UserProfileBasicBlockWeb from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import MemberInvite from '../../blocks/email-account-registration/src/MemberInvite.web';
import TicketSystem from '../../blocks/ticketsystem/src/TicketSystem.web';
import SupportChat from '../../blocks/ticketsystem/src/SupportChat';
import PayoutSettings from '../../blocks/contractmanagement2/src/PayoutSettings.web';
import Payouts from "../../blocks/commissionreports2/src/Payout.web"
import EducationalUserProfileWeb from "../../blocks/educational-user-profile/src/EducationalUserProfile.web";
import RegisterReferral from "../../blocks/leadmanagement/src/RegisterReferral.web";
import ViewDrafts from "../../blocks/leadmanagement/src/ViewDrafts.web"

const routeMap = {
  Companycontactpage2: {
    component: Companycontactpage2,
    path: "/companies"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  EducationalUserProfileWeb: {
    component: EducationalUserProfileWeb,
    path: "/training"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Brandingcustomisation2: {
    component: Brandingcustomisation2,
    path: "/Brandingcustomisation2"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Contractmanagement2: {
    component: Contractmanagement2,
    path: "/teams"
  },
  ContractDetail: {
    component: ContractDetail,
    path: "/contract-details"
  },
  
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/user-profile"
  },
  UserProfileBasicBlockWeb: {
    component: UserProfileBasicBlockWeb,
    path: "/UserProfileBasicBlockWeb"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  Commissionreports2: {
    component: Commissionreports2,
    path: "/commissions"
  },
  Posintegration3: {
    component: Posintegration3,
    path: "/Posintegration3"
  },
  Salesforceintegration2: {
    component: Salesforceintegration2,
    path: "/Salesforceintegration2"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Singleleaddealpage3: {
    component: Singleleaddealpage3,
    path: "/Singleleaddealpage3"
  },

  Home: {
    component: EmailAccountLoginBlockWeb,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/info-page'
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  EmailAccountLoginBlockWeb: {
    component: EmailAccountLoginBlockWeb,
    path: "/login"
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: "/forgot-password"
  },
  ForgotPasswordInstruction: {
    component: ForgotPasswordInstruction,
    path: "/forgot-password-instruction"
  },
  NewPasswordWeb: {
    component: NewPasswordWeb,
    path: "/NewPasswordWeb"
  },
  SuccessResetPassword: {
    component: SuccessResetPassword,
    path: "/SuccessResetPassword"
  },
  EmailAccountRegistrationInvite: {
    component: EmailAccountRegistrationInvite,
    path: "/EmailAccountRegistrationInvite"
  },
  DashboardWeb: {
    component: DashboardWeb,
    path: "/dashboard"
  },
  LeadCreateSuccess: {
    component: LeadCreateSuccess,
    path: "/LeadCreateSuccess"
  },
  LeadDashboard: {
    component: LeadDashboard,
    path: "/referrals"
  },
  LeadDetial: {
    component: ReferralsDetail,
    path: "/lead/:id"
  },
  AssetsDashboardWeb: {
    component: AssetsDashboardWeb,
    path: "/asset-library"
  },
  MemberInvite: {
    component: MemberInvite,
    path: "/MemberInvite"
  },
  TicketSystem:{
    component: TicketSystem,
    path: "/TicketSystem"
  },
  SupportChat:{
    component: SupportChat,
    path: "/SupportChat"
  },  
  PayoutSettings:{
    component: PayoutSettings,
    path: "/payout-settings"
  },  
  Payouts:{
    component: Payouts,
    path: "/payouts"
  },  
  RegisterReferral: {
    component: RegisterReferral,
    path: "/register-referral"
  },
  ViewDrafts: {
    component: ViewDrafts,
    path: "/view-draft"
  },
  DealDetial: {
    component: ReferralsDetail,
    path: "/deal/:id"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100%' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
