import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export function createRequest(request: {
    requestMsg: Message;
    endPoint: string;
    header?: object;
    method: string;
    token?: string;
    body?: string | FormData;
}) {
    const { requestMsg, endPoint, header, method, token, body } = request;

    const convertHeader = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Token": token ?? undefined,
        ...header,
    };

    requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint,
    );

    requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(convertHeader),
    );

    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

    body &&
    requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);

    runEngine.sendMessage(requestMsg.id, requestMsg);
}

export default createRequest;
