Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "assetsdashboard";
exports.labelBodyText = "assetsdashboard Body";

exports.btnExampleTitle = "CLICK ME";
exports.getAssetsEndPoint = "bx_block_user_library/user_libraries"
exports.getSingleAssetEndPoint = "bx_block_user_library/user_libraries/show_user_library"
exports.getFiltersEndPoint = "bx_block_user_library/user_libraries/assest_filter"
// Customizable Area End