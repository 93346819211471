// Customizable Area Start
import { Box } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface myProps {
  hasTabbing ? :boolean
}



export default function TableFilterSkeleton(props: myProps) {  
  return (
    <>
    {props.hasTabbing && (
    <Box className="tabbing-skeleton">
      <Box className="tabbing-inner-wrap">
        <Skeleton height={16} width={105} />
        <Skeleton height={16} width={105} />
      </Box>
    </Box>
    )}
    <Box className="table-filter-skeleton" style={{padding:'15px 0 10px'}}>
      <Box className="filter-inner-wrap">
        <Box className="left-block">
          <Skeleton height={16} width={105} />
          <Skeleton height={36} width={138} />
        </Box>
        <Box className="right-block">
          <Skeleton height={36} width={296} />
          <Skeleton height={36} width={36} />
        </Box>
      </Box>
    </Box>
    </>
  )
}

// Customizable Area End