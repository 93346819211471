import React from "react";
// Customizable Area Start
import { Box, Grid, List, ListItem, Typography } from "@material-ui/core";
import { getDateFormat, getNullValues, getColorsForStatus, capitalizeFirstLetter, getZeroValue, getSingleLeadName } from "../../../components/src/commonUsage";
import UpdateLeadSkeleton from "./UpdateLeadSkeleton";
import moment from "moment";
// Customizable Area End
import LeadManagementController, {
  Props
} from "./LeadManagementController";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import './css/styles.css'

export default class ReferralsDetail extends LeadManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const id=window.location.pathname.split('/')
    if(id.length===3){
      if(id[1]==='lead')
        this.getSingleLead(id[2])
      else
        this.getSingleLead(id[2],"opp")
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    let { lead_name, value, deal_status, customer_email, first_name, last_name, updated_at, closed_won_at, company_name, country, company_website, type, name } = this.state.singleLeadInfo
    return (
      //Merge Engine DefaultContainer
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar backPage={{ name: "Go to referrals", path: 'referrals' }} />
              {
                this.state.isLoading?
                  // <Loader loading={this.state.isLoading} contentLoader={true} />
                  <UpdateLeadSkeleton />
                  :
                  <Box style={webStyles.mainContainer}>
                    <Grid>
                      <Typography className="deal-heading-main referral-update-main" style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                        {capitalizeFirstLetter(getSingleLeadName(type, name, lead_name))} <label className="deal-status-label" style={{ marginLeft: '10px' }}><Typography
                          style={{
                            textTransform: 'capitalize',
                            display: 'inline-flex',
                            padding: '2px 8px 1px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '32px',
                            border: `1px solid ${getColorsForStatus(deal_status).border}`,
                            background: getColorsForStatus(deal_status).surface,
                            width: 'fit-content',
                          }}
                        ><label
                          style={{
                            color: getColorsForStatus(deal_status).main,
                            textAlign: 'center',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '15px',
                            letterSpacing: '0.12px',
                            whiteSpace: 'nowrap'
                          }}>{getColorsForStatus(deal_status).label}</label></Typography></label>
                      </Typography>
                    </Grid>
                    <div>
                      <Box className="my-store-row-wrap">
                        <Box className="my-store-form-col">
                          <Box className="referral-form-wrapper">
                            <Box className="deal-update-form">
                              <Box className="referral-form-content">
                                <Box className="referral-form-container">
                                  <Typography className="deal-form-heading">Basic information</Typography>
                                  <Box className="deal-form-internal-container">
                                    <Box>
                                      <Grid className="grid-even-space">
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            First name
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.first_name)}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Last name
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.last_name)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box className="mt-1">
                                      <Grid className="grid-even-space">
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Email
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.customer_email)}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Phone number
                                          </Typography>
                                          <Typography className="referral-content">
                                          {this.state.singleLeadInfo?.phone_number?`${this.state.singleLeadInfo?.country_code} ${this.state.singleLeadInfo?.phone_number}`:"-"}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box className="mt-1">
                                      <Grid className="grid-even-space">
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Position
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.designation)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box className="referral-form-container">
                                  <Typography className="deal-form-heading">Company information</Typography>
                                  <Box className="deal-form-internal-container">
                                    <Box>
                                      <Grid className="grid-even-space">
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Company name
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(company_name)}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Country
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.lead_country || this.state.singleLeadInfo.country)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box className="mt-1">
                                      <Grid className="grid-even-space">
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Industry
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.company_industry)}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Company website
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.company_website)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box className="mt-1">
                                      <Grid className="grid-even-space">
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Number of employees
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.company_size)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box className="referral-form-container">
                                  <Typography className="deal-form-heading">Lead qualification</Typography>
                                  <Box className="deal-form-internal-container">
                                    <Box>
                                      <Grid className="grid-even-space">
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Product type
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.product_type)}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Client persona description
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.best_describe)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box className="mt-1">
                                      <Grid className="grid-even-space">
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Client budget
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.budget)}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            First contact date
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.state.singleLeadInfo.contact_date?.length?moment(this.state.singleLeadInfo.contact_date).format("DD/MM/YYYY"):"-"}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box className="mt-1">
                                      <Grid className="grid-even-space">
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            What platform would they like
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.platform_type)}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Timeline for project delivery
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.software_delivered)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box className="mt-1">
                                      <Grid item>
                                        <Typography className="referral-title">
                                          What is their product idea
                                        </Typography>
                                        <Typography className="referral-content">
                                          {this.isEmpty(this.state.singleLeadInfo.product_idea)}
                                        </Typography>
                                      </Grid>
                                    </Box>
                                    <Box className="mt-1">
                                      <Grid className="grid-even-space">
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            App category
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.app_category)}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Typography className="referral-title">
                                            Their stage of idea
                                          </Typography>
                                          <Typography className="referral-content">
                                            {this.isEmpty(this.state.singleLeadInfo.idea_stage)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box className="referral-form-container">
                                  <Typography className="deal-form-heading">Additional notes</Typography>
                                  <Box className="deal-form-internal-container">
                                    <Box>
                                      <Grid item>
                                        <Typography className="referral-title">
                                          Additional notes
                                        </Typography>
                                        <Typography className="referral-content">
                                          {this.isEmpty(this.state.singleLeadInfo.description)}
                                        </Typography>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="my-store-detail-col update-container">
                          <Box className="deal-info-wrap">
                            <Box className="deal-update-save-form-2">
                              <Box className="deal-title-block">
                                <Box>
                                  <Typography className="update-heading">{capitalizeFirstLetter(getSingleLeadName(type, name, lead_name))}</Typography>
                                  <Typography
                                    style={{
                                      textTransform: 'capitalize',
                                      display: 'inline-flex',
                                      padding: '2px 8px 1px',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderRadius: '32px',
                                      border: `1px solid ${getColorsForStatus(deal_status).border}`,
                                      background: getColorsForStatus(deal_status).surface,
                                      width: 'fit-content',
                                    }}
                                  ><label
                                    style={{
                                      color: getColorsForStatus(deal_status).main,
                                      textAlign: 'center',
                                      fontSize: '14.22px',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      lineHeight: '18.96px',
                                      letterSpacing: '0.01em',
                                      whiteSpace: 'nowrap'

                                    }}>{getColorsForStatus(deal_status).label}</label></Typography>
                                </Box>
                                <Grid className="update-deal-value-container mt-1">
                                  <Grid className="lead-commision-value">
                                      <div className="lead-value-box">
                                        <Typography className="update-heading-deal-value">Value</Typography>
                                        <Typography className="update-heading-deal-price">{value ? '$' : ''}{`${getZeroValue(value||"")}`}</Typography>
                                      </div>
                                      <div className="commssion-value-box">
                                        <Typography className="update-heading-deal-value">Commission</Typography>
                                        <Typography className="update-heading-deal-price">-</Typography>
                                      </div>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box className="info-content-row" style={{ borderTop: 'none', paddingTop: '0' }}>
                                <Box className="update-deal-value-container">
                                  <Box className="inner-content-row">
                                    <Box className="title">
                                      <Typography className="update-heading-deal-value">Last updated</Typography>
                                    </Box>
                                    <Box className="value">
                                      <Typography className="update-heading-deal-info">{getDateFormat(updated_at)}</Typography>
                                    </Box>
                                  </Box>
                                  <Box className="inner-content-row">
                                    <Box className="title">
                                      <Typography className="update-heading-deal-value">Closed date</Typography>
                                    </Box>
                                    <Box className="value">
                                      <Typography className="update-heading-deal-info">{getNullValues(getDateFormat(closed_won_at))}</Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>                                                            
                              {
                                this.state.singleLeadInfo.deal_status?.toLowerCase() == "disqualified" ?
                                  <Box className="reason-content-row">
                                    <Box className="reason-value-container">
                                      <Box>
                                        <Box className="title">
                                          <Typography className="reason-title">Disqualification reason</Typography>
                                        </Box>
                                        <Box className="value">
                                          <Typography className="reason-value">
                                            {this.state.singleLeadInfo.disqualified_reason}
                                          </Typography>
                                          {
                                            this.state.singleLeadInfo.disqualified_sub_reason &&
                                            <List className="sub-reason-list">
                                              <ListItem className="sub-reason">
                                                {this.state.singleLeadInfo.disqualified_sub_reason}
                                              </ListItem>
                                            </List>
                                          }
                                        </Box>
                                      </Box>
                                      {
                                        this.state.singleLeadInfo.disqualification_details &&
                                        <Box className="mt-1">
                                          <Box className="title">
                                            <Typography className="reason-title">Disqualification details</Typography>
                                          </Box>
                                          <Box className="value">
                                            <Typography className="reason-value">
                                              {this.state.singleLeadInfo.disqualification_details}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      }
                                    </Box>
                                  </Box>
                                  :
                                  <></>
                              }
                              {this.state.singleLeadInfo.deal_status?.toLowerCase() == "closed lost" ?
                                <Box className="reason-content-row">
                                <Box className="reason-value-container">
                                  <Box>
                                    <Box className="title">
                                      <Typography className="reason-title">Closed lost reason</Typography>
                                    </Box>
                                    <Box className="value">
                                      <Typography className="reason-value">
                                        {this.state.singleLeadInfo.close_lost_reason}
                                      </Typography>
                                        {
                                          this.state.singleLeadInfo.close_lost_sub_reason &&
                                          <List className="sub-reason-list">
                                            <ListItem className="sub-reason">
                                            {this.state.singleLeadInfo.close_lost_sub_reason}
                                            </ListItem>
                                          </List>
                                        }
                                    </Box>
                                  </Box>
                                    {
                                      this.state.singleLeadInfo.close_lost_details &&
                                      <Box className="mt-1">
                                        <Box className="title">
                                          <Typography className="reason-title">Closed lost details</Typography>
                                        </Box>
                                        <Box className="value">
                                          <Typography className="reason-value">
                                            {this.state.singleLeadInfo.close_lost_details}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    }
                                </Box>
                              </Box>:<></>}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </div>

                  </Box>
              }
            </Grid>
          </Grid>
        </Grid>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0'
  },
};
// Customizable Area End
