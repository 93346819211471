// Customizable Area Start
import { Box, Card, CardActions, CardContent, Grid } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface myProps {
  hasFourCols ?: boolean;
  hasViewMore ?: boolean;
}

export default function AssetDashboardSkeleton(props: myProps) {
  return (
    <>    
    <Grid container className="grid-even-asset" style={{marginTop:'24px'}}>
      <Grid item xs={6} md={3} className="card-wrap">
        <Card className="asset-card">
          <Skeleton height={164} className="card-image-skeleton" />
          <CardContent
            className="card-content-wrap"
          >
            <Skeleton height={21} width={150} style={{marginBottom:'5px'}} />
            <Skeleton height={14} width={60} />
          </CardContent>
          <CardActions className="asset-actions">
          <Skeleton height={36} width={116} />
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6} md={3} className="card-wrap">
        <Card className="asset-card">
          <Skeleton height={164} className="card-image-skeleton" />
          <CardContent
            className="card-content-wrap"
          >
            <Skeleton height={21} width={150} style={{marginBottom:'5px'}} />
            <Skeleton height={14} width={60} />
          </CardContent>
          <CardActions className="asset-actions">
          <Skeleton height={36} width={116} />
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6} md={3} className="card-wrap">
        <Card className="asset-card">
          <Skeleton height={164} className="card-image-skeleton" />
          <CardContent
            className="card-content-wrap"
          >
            <Skeleton height={21} width={150} style={{marginBottom:'5px'}} />
            <Skeleton height={14} width={60} />
          </CardContent>
          <CardActions className="asset-actions">
          <Skeleton height={36} width={116} />
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6} md={3} className="card-wrap">
        <Card className="asset-card">
          <Skeleton height={164} className="card-image-skeleton" />
          <CardContent
            className="card-content-wrap"
          >
            <Skeleton height={21} width={150} style={{marginBottom:'5px'}} />
            <Skeleton height={14} width={60} />
          </CardContent>
          <CardActions className="asset-actions">
          <Skeleton height={36} width={116} />
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6} md={3} className="card-wrap">
        <Card className="asset-card">
          <Skeleton height={164} className="card-image-skeleton" />
          <CardContent
            className="card-content-wrap"
          >
            <Skeleton height={21} width={150} style={{marginBottom:'5px'}} />
            <Skeleton height={14} width={60} />
          </CardContent>
          <CardActions className="asset-actions">
          <Skeleton height={36} width={116} />
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6} md={3} className="card-wrap">
        <Card className="asset-card">
          <Skeleton height={164} className="card-image-skeleton" />
          <CardContent
            className="card-content-wrap"
          >
            <Skeleton height={21} width={150} style={{marginBottom:'5px'}} />
            <Skeleton height={14} width={60} />
          </CardContent>
          <CardActions className="asset-actions">
          <Skeleton height={36} width={116} />
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6} md={3} className="card-wrap">
        <Card className="asset-card">
          <Skeleton height={164} className="card-image-skeleton" />
          <CardContent
            className="card-content-wrap"
          >
            <Skeleton height={21} width={150} style={{marginBottom:'5px'}} />
            <Skeleton height={14} width={60} />
          </CardContent>
          <CardActions className="asset-actions">
          <Skeleton height={36} width={116} />
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6} md={3} className="card-wrap">
        <Card className="asset-card">
          <Skeleton height={164} className="card-image-skeleton" />
          <CardContent
            className="card-content-wrap"
          >
            <Skeleton height={21} width={150} style={{marginBottom:'5px'}} />
            <Skeleton height={14} width={60} />
          </CardContent>
          <CardActions className="asset-actions">
          <Skeleton height={36} width={116} />
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6} md={3} className="card-wrap">
        <Card className="asset-card">
          <Skeleton height={164} className="card-image-skeleton" />
          <CardContent
            className="card-content-wrap"
          >
            <Skeleton height={21} width={150} style={{marginBottom:'5px'}} />
            <Skeleton height={14} width={60} />
          </CardContent>
          <CardActions className="asset-actions">
          <Skeleton height={36} width={116} />
          </CardActions>
        </Card>
      </Grid>
    </Grid>
    </>
  )
}
// Customizable Area End