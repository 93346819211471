import React from "react";

//Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import "./css/style.css";
import { builderPartner } from "./assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik } from 'formik';
import { registrationInviteSchema } from "../../../components/src/validations";
import { openEye, visiblityOff } from "../../email-account-login/src/assets";
//@ts-ignore
import loginBg from '../assets/login_background.png';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
//Customizable Area End

export default class NewPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  //Customizable Area Start
  async componentDidMount() {
    this.getPartnerEmail()
  }
  //Customizable Area End

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        
          <Box className="login-container-box"
          style={{
            backgroundImage: `url(${loginBg})`              
          }}>
            <Box className="login-container" >
            <Formik
              enableReinitialize
              initialValues={{ password: this.state.password, reTypePassword: this.state.reTypePassword }}
              validationSchema={registrationInviteSchema}
              onSubmit={() => {
                this.setState({
                  errorMessage: null
                })
                this.createPasswordInvite()
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Box style={{textAlign:'center'}}>
                    <div className="logo-block">
                      <img src={builderPartner} className="login-partner-logo" />                      
                    </div>
                    <div className="title-block">
                      <Typography className="set-password-text">
                        Set a new password
                      </Typography>
                      <Typography className="set-passwrod-sub-text">
                      Set password for <span>{this.state.emailValue}</span>
                    </Typography>
                    </div>
                   
                    <Box className="login-input-container">
                      <Box >
                        <TextField
                          size="small"
                          variant="filled"
                          data-test-id="txtInputPassword"
                          type={this.state.enablePasswordField ? "password" : "text"}
                          label={"Set Password"}
                          fullWidth={true}
                          value={formik.values.password}
                          onChange={(e) => {
                            this.setPassword(e.target.value)
                            formik.handleChange(e)
                          }}
                          onBlur={formik.handleBlur}
                          error={formik.touched.password && Boolean(formik.errors.password) || this.state.errorMessage}
                          helperText={formik.touched.password && formik.errors.password}
                          InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  edge="end"
                                >
                                  {this.state.enablePasswordField ? (
                                    <img src={openEye} />
                                  ) : (
                                    <img src={visiblityOff} alt="" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                          }}
                        />
                      </Box>
                      <Box style={{marginTop:'16px'}}>
                        <TextField
                          size="small"
                          variant="filled"
                          data-test-id="txtInputPassword"
                          type={this.state.enableConfirmPasswordField ? "password" : "text"}
                          label={"Confirm Password"}
                          fullWidth={true}
                          value={formik.values.reTypePassword}
                          onChange={(e) => {
                            this.setReTypePassword(e.target.value)
                            formik.handleChange(e)
                          }}
                          onBlur={formik.handleBlur}
                          error={formik.touched.reTypePassword && Boolean(formik.errors.reTypePassword)|| this.state.errorMessage}
                          helperText={formik.touched.reTypePassword && formik.errors.reTypePassword}
                          InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickConfirmShowPassword}
                                  edge="end"
                                >
                                  {this.state.enableConfirmPasswordField ? (
                                    <img src={openEye} />
                                  ) : (
                                    <img src={visiblityOff} alt="" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                          }}
                        />
                      </Box>
                      {
                        this.state.errorMessage !== null &&
                        <Box>
                          <Typography className="error-text">
                            {this.state.errorMessage}
                          </Typography>
                        </Box>
                      }
                      <Box
                        style={{
                          marginTop:'24px'
                        }}
                      >
                        <Button
                          data-test-id={"btnEmailLogIn"}
                          variant="contained"                          
                          className="custom-btn green-btn"
                          fullWidth
                          type="submit"
                        >
                          <Typography className="btn-create-password">
                            Create Password {/*UI Engine::From Sketch*/}
                          </Typography>
                        </Button>
                      </Box>
                      <Box
                        style={{
                          marginTop:'24px',
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center'                          
                        }}
                      >
                        <Typography
                          className="btn-back-signin"
                          onClick={() => this.backToLogin()}
                        >
                          Back to Sign in {/*UI Engine::From Sketch*/}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}

            </Formik>
            </Box>
          </Box>
        
      </ThemeProvider>
      // Customizable Area End

    );
  }
}

// Customizable Area Start
