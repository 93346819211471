// Customizable Area Start
import { Box } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface myProps {
  hasFourCols ?: boolean;  
  hasViewMore ?: boolean;
}



export default function BoxSkeleton(props: myProps) {  
  return (
    <Box className="box-list-wrapper" style={{marginTop:'16px'}}>
    <Box className={`box-list-skeleton ${props.hasFourCols ? 'has-four-cols' : ''}`}>
      <Box className="box-item-skeleton">
        <Skeleton variant="text" width={211} height={19} className="title-skeleton" />
        <Box className="detail-wrapper">                            
          <Skeleton height={40} width={40} />
          <Box className="content-wrapper">
            <Box className="top-text">
            <Skeleton variant="text" width={112} height={16}  />
            </Box>
            <Skeleton variant="text" width={105} height={11}  />
          </Box>
        </Box>
      </Box>
      <Box className="box-item-skeleton">
        <Skeleton variant="text" width={211} height={19} className="title-skeleton" />
        <Box className="detail-wrapper">                            
          <Skeleton height={40} width={40} />
          <Box className="content-wrapper">
            <Box className="top-text">
            <Skeleton variant="text" width={112} height={16}  />
            </Box>
            <Skeleton variant="text" width={105} height={11}  />
          </Box>
        </Box>
      </Box>
      <Box className="box-item-skeleton">
        <Skeleton variant="text" width={211} height={19} className="title-skeleton" />
        <Box className="detail-wrapper">                            
          <Skeleton height={40} width={40} />
          <Box className="content-wrapper">
            <Box className="top-text">
            <Skeleton variant="text" width={112} height={16}  />
            </Box>
            <Skeleton variant="text" width={105} height={11}  />
          </Box>
        </Box>
      </Box>
      {props.hasFourCols && (
        <Box className="box-item-skeleton">
        <Skeleton variant="text" width={211} height={19} className="title-skeleton" />
        <Box className="detail-wrapper">                            
          <Skeleton height={40} width={40} />
          <Box className="content-wrapper">
            <Box className="top-text">
            <Skeleton variant="text" width={112} height={16}  />
            </Box>
            <Skeleton variant="text" width={105} height={11}  />
          </Box>
        </Box>
      </Box>
      )}
    </Box>
    {props.hasViewMore && (
    <Box className="view-more-skeleton">
      <Skeleton variant="text" width={155} height={12} />
    </Box>
    )}
  </Box>
  )
}

// Customizable Area End