export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const deals = require("../assets/deals.svg")
export const accordition_icon = require("../assets/accordition_icon.svg");
export const customer = require("../assets/customer.svg");
export const others = require("../assets/others.svg");
export const dealSuccess = require("../assets/deal_success.svg");
export const rightCheck = require("../assets/right_check.svg");
export const dealsMain = require("../assets/dealsmain.svg");
export const searchIcon = require("../assets/search.svg")
export const downloadIcon = require("../assets/download.svg")
export const deleteIcon = require("../assets/delete.svg")
export const closeIcon = require("../assets/close.svg")
export const graphBars = require("../assets/bars.svg")
export const leadsGraphBars = require("../assets/leads_bar.svg")
export const emptySvg = require("../assets/empty.svg")
export const uploadIcon = require("../assets/upload.svg")
export const filesUploaded = require("../assets/files_uploaded.svg")
export const uploadFile = require("../assets/upload_file.svg")
export const dealsGraph = require("../assets/deals-graph.svg")
export const pieChart = require("../assets/pie-chart.svg")
export const addPlus =require("../assets/plusIcon.png")
export const addPlusColor=require("../assets/plusIconcolor.png")
export const emptyAssets=require("../assets/empty_assets.svg")
export const registerDeal=require("../assets/register_deal.svg")
export const registerReferral=require("../assets/referral-success.svg")
export const viewDraft=require("../assets/view_draft.svg")
export const infoIcon = require("../assets/info_icon.svg");
export const registerLead=require("../assets/register_lead.svg")
export const downArrow = require("../assets/down_arrow.svg");
export const calendarIcon = require("../assets/calendar_icon.svg");
export const leftArrowIcon = require("../assets/leftArrow.png");
export const rightArrowIcon = require("../assets/rightArrow.png");
export const closeWhiteIcon = require("../assets/close-white.svg");
export const starIcon = require("../assets/star.svg");
export const starFillIcon = require("../assets/star-fill.svg");