// Customizable Area Start
import { Box } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import PageHeadingSkeleton from "../../../components/src/PageHeadingSkeleton";
import TopNavSkeleton from "../../../components/src/TopNavSkeleton";
import Contractmanagement2Controller from "./Contractmanagement2Controller";

export default class PayoutSettingsSkeleton extends Contractmanagement2Controller {
  render() {
    return (
      <Box className="payout-settings-skeleton">
        <TopNavSkeleton />
        <PageHeadingSkeleton />
        <Box className="bank-accout-detail-wrapper">
          <Box className="account-listing-wrap">
            <Box className="add-account-block bank-account-detail-block">
              <Box className="detail-inner-wrap">
                <Skeleton height={32} width={32} style={{ marginBottom: '15px' }} />
                <Skeleton height={18} width={100} />
              </Box>
            </Box>
            <Box className="bank-account-detail-block">
              <Box className="detail-inner-wrap">
                <Box className="account-title-block">
                  <Skeleton height={32} width={32} />
                  <Skeleton height={16} width={100} />
                  <Skeleton height={24} width={50} />
                </Box>
                <Box className="account-detail-block">
                  <Box className="account-detail-title">
                    <Skeleton height={20} width={105} />
                    <Skeleton height={20} width={80} />
                  </Box>
                  <Box className="account-description">
                    <Box className="description-item">
                      <Skeleton height={14} width={105} style={{ marginRight: '6px' }} />
                      <Skeleton height={14} width={125} style={{ marginLeft: 'auto' }} />
                    </Box>
                    <Box className="description-item">
                      <Skeleton height={14} width={125} style={{ marginRight: '6px' }} />
                      <Skeleton height={14} width={145} style={{ marginLeft: 'auto' }} />
                    </Box>
                    <Box className="description-item">
                      <Skeleton height={14} width={105} style={{ marginRight: '6px' }} />
                      <Skeleton height={14} width={125} style={{ marginLeft: 'auto' }} />
                    </Box>
                  </Box>
                  <Box className="account-detail-footer">
                    <Box className="links-list" style={{ marginTop: '5px' }}>
                      <Skeleton height={16} width={80} style={{ marginRight: '20px' }} />
                      <Skeleton height={16} width={80} style={{ marginRight: '20px' }} />
                      <Skeleton height={16} width={80} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="bank-account-detail-block">
              <Box className="detail-inner-wrap">
                <Box className="account-title-block">
                  <Skeleton height={32} width={32} />
                  <Skeleton height={16} width={100} />
                  <Skeleton height={24} width={50} />
                </Box>
                <Box className="account-detail-block">
                  <Box className="account-detail-title">
                    <Skeleton height={20} width={105} />
                    <Skeleton height={20} width={80} />
                  </Box>
                  <Box className="account-description">
                    <Box className="description-item">
                      <Skeleton height={14} width={105} style={{ marginRight: '6px' }} />
                      <Skeleton height={14} width={125} style={{ marginLeft: 'auto' }} />
                    </Box>
                    <Box className="description-item">
                      <Skeleton height={14} width={125} style={{ marginRight: '6px' }} />
                      <Skeleton height={14} width={145} style={{ marginLeft: 'auto' }} />
                    </Box>
                    <Box className="description-item">
                      <Skeleton height={14} width={105} style={{ marginRight: '6px' }} />
                      <Skeleton height={14} width={125} style={{ marginLeft: 'auto' }} />
                    </Box>
                  </Box>
                  <Box className="account-detail-footer">
                    <Box className="links-list" style={{ marginTop: '5px' }}>
                      <Skeleton height={16} width={80} style={{ marginRight: '20px' }} />
                      <Skeleton height={16} width={80} style={{ marginRight: '20px' }} />
                      <Skeleton height={16} width={80} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="bank-account-detail-block">
              <Box className="detail-inner-wrap">
                <Box className="account-title-block">
                  <Skeleton height={32} width={32} />
                  <Skeleton height={16} width={100} />
                  <Skeleton height={24} width={50} />
                </Box>
                <Box className="account-detail-block">
                  <Box className="account-detail-title">
                    <Skeleton height={20} width={105} />
                    <Skeleton height={20} width={80} />
                  </Box>
                  <Box className="account-description">
                    <Box className="description-item">
                      <Skeleton height={14} width={105} style={{ marginRight: '6px' }} />
                      <Skeleton height={14} width={125} style={{ marginLeft: 'auto' }} />
                    </Box>
                    <Box className="description-item">
                      <Skeleton height={14} width={125} style={{ marginRight: '6px' }} />
                      <Skeleton height={14} width={145} style={{ marginLeft: 'auto' }} />
                    </Box>
                    <Box className="description-item">
                      <Skeleton height={14} width={105} style={{ marginRight: '6px' }} />
                      <Skeleton height={14} width={125} style={{ marginLeft: 'auto' }} />
                    </Box>
                  </Box>
                  <Box className="account-detail-footer">
                    <Box className="links-list" style={{ marginTop: '5px' }}>
                      <Skeleton height={16} width={80} style={{ marginRight: '20px' }} />
                      <Skeleton height={16} width={80} style={{ marginRight: '20px' }} />
                      <Skeleton height={16} width={80} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
}
// Customizable Area End